import React, { useState, useContext, useEffect, useRef } from "react";
import { SiteContext } from "../contexts/SiteContext";
import PopupDetails from "../components/popups/PopupDetails";
import PopupService from "../components/popups/PopupService";
import PopupVessel from "../components/popups/PopupVessel";
import PopupVoucher from "../components/popups/PopupVoucher";
import PopupExtras from "../components/popups/PopupExtras";
import OrderRecord from "./OrderRecord";
import { useParams } from "react-router";
import axios from 'axios'
import _ from "lodash"

const SelectService = () => {
    const objectID = localStorage.getItem('activeOrder') || 0
    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const [services, setServices] = useState(false)
    const [categories, setCategories] = useState(false)
    const [hide, setHide] = useState(false);
    const contextStorage = useContext(SiteContext)
    const currObj = contextStorage.order[objectID ? objectID : 0]
    // const contextStorage.order = { 0: JSON.parse(localStorage.getItem('orders')) }
    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView()

    useEffect(() => {
        // console.log(contextStorage.order)
        // console.log(contextStorage.order)

        if (contextStorage.order[objectID ? objectID : 0] != null) contextStorage.setTmpOrder(true)
    }, [contextStorage.order]);

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get("https://deinze-kajakt-api.vercel.app/api/categories/"+process.env.REACT_APP_LOCATION).then(res => {
            // console.log(res)
            const cats = res.data
            cats.sort((a, b) => a.sort - b.sort)
            setCategories(cats)
        }).catch(err => console.log(err))
    }, [])


    const makeid = () => {
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < 20; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        return text
    }

    const handleProgress = () => {
        filterBadOrders()
        contextStorage.setProgressCounter(3)
        contextStorage.setProgressCheck(3)
    }

    const handleClick = (param) => {
        console.log(param)
        if (param.redirect && param.redirect !== "undefined") {
            window.location = param.redirect
            return
        }

        console.log('ObjectID', objectID)
        if (contextStorage.order[objectID ? objectID : 0].choseTimeAndSeats) {
            // console.log('ADD')
            contextStorage.addOrder(true)
        }


        contextStorage.setPopupStatus(true)
        contextStorage.setCategory(param.id)
        // console.log('postaddOrder')
        const tmp = contextStorage.order[objectID ? objectID : 0]
        tmp.service = param.name
        // tmp.img = param.img
        tmp.category = param.id

        contextStorage.setOrder((prevState) => ({
            ...prevState,
            [objectID ? objectID : 0]: tmp
        }))
        contextStorage.setPopupCounter(1)
    }

    useEffect(() => {
        // console.log(contextStorage.order)
    }, [contextStorage]);

    useEffect(() => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            contextStorage.popupCounter === 5 && executeScroll()
        } else {
            console.log('no mobile device')
            // document.write("not mobile device");
        }

    }, [contextStorage.popupCounter]);


    const getHighestIndex = () => {
        const keyArr = []
        Object.keys(contextStorage.order).forEach(key => keyArr.push(parseInt(key)))
        return Math.max.apply(Math, keyArr)
    }

    const nextStepValidation = () => {
        // console.log(Object.values(contextStorage.order).find(order => order.completion))
        return Object.values(contextStorage.order).find(order => order.completion)
    }

    const filterBadOrders = () => {
        if (contextStorage.order[getHighestIndex()] && !contextStorage.order[getHighestIndex()].completion && !contextStorage.order[getHighestIndex()].choseTimeAndSeats) {
            // console.log('EMPTY ORDER')
            const copy = _.cloneDeep(contextStorage.order)
            // console.log(copy)
            delete copy[getHighestIndex()]
            // console.log(copy)
            localStorage.setItem('activeOrder', getHighestIndex() - 1)
            contextStorage.setOrder(copy)
        }
    }

    return (
        <>
            {contextStorage.popupStatus ? <>
                {contextStorage.popupCounter == 1 && <PopupService />}
                {contextStorage.popupCounter == 2 && <PopupDetails />}
                {contextStorage.popupCounter == 3 && <PopupVessel />}
                {contextStorage.popupCounter == 4 && <PopupExtras />}
            </> : ""
            }
            <div className={`mx-auto justify-center flex-col lg:flex-row lg:py-32 pt-10 lg:pt-20 w-full ${hide ? 'hidden' : 'flex'}`} key={makeid()} >
                <div className="bg-slate-100 p-4 xl:p-8 rounded-tr-xl rounded-tl-xl lg:rounded-xl flex-col w-full lg:w-3/5">
                    <h2 className=" text-xl text-center lg:text-left font-bold border-b border-gray-400 pb-4 mb-6">Kies uw activiteit</h2>
                    <div className="grid lg:grid-cols-3 gap-7">
                        {categories && categories.map((item, index) =>
                            (item.active === true || item.active === "true") ? <div key={index} className="flex flex-col rounded-md overflow-hidden group cursor-pointer shadow-lg" onClick={() => handleClick(item)}>
                                <div className="h-44 relative overflow-hidden">
                                    <img src={item.img} alt="" className=" h-full w-full absolute inset-0 object-cover group-hover:scale-125 transition-all duration-500" />
                                    <div className="bg-gray-300 absolute inset-0 opacity-0 transition-all duration-300"></div>
                                </div>
                                <div className=" flex flex-col py-5 px-3 justify-center w-full h-36 items-center bg-white group-hover:bg-primaryBlue group-hover:text-white transition-all duration-300">
                                    <h3 className=" font-bold text-xl mb-2">{item.name}</h3>
                                    <p className=" text-center">{item.beschrijving}</p>
                                    <p>{item.price}</p>
                                </div>
                            </div> :
                                <div key={index} className="flex flex-col rounded-md overflow-hidden shadow-lg opacity-40">
                                    <div className="h-44 relative">
                                        <img src={item.img} alt="" className=" h-full w-full absolute inset-0 object-cover" />
                                        <div className="bg-gray-300 absolute inset-0 opacity-0 transition-all duration-300"></div>
                                    </div>
                                    <div className=" flex flex-col py-5 px-3 justify-center w-full items-center bg-white">
                                        <h3 className=" font-bold text-xl mb-2">{item.name}</h3>
                                        <p>Niet beschikbaar</p>
                                    </div>
                                </div>
                        )}
                    </div>
                </div>
                <div className="w-6" />

                <div ref={myRef} className="bg-slate-100 p-4 xl:p-8 lg:rounded-xl rounded-br-xl rounded-bl-xl flex-col w-full lg:w-2/5">
                    <h2 className=" text-xl font-bold border-b border-gray-400 pb-4 mb-6">Uw reservatie</h2>
                    <div className="flex flex-col lg:flex-row text-lg">
                        <h3 className=" mr-12 whitespace-nowrap">Geselecteerde datum <br /> <strong>{contextStorage.keepDate ? new Date(contextStorage.keepDate).toLocaleDateString("nl-BE", options) : new Date(contextStorage.order[objectID ? objectID : 0].date).toLocaleDateString("nl-BE", options)}</strong></h3>
                        <button className=" bg-primaryBlue bg w-full py-2 mt-3 lg:mt-0 text-center text-white rounded-md hover:bg-cyan-800 transition-all text-md px-4" onClick={() => contextStorage.setProgressCounter(1)}>Datum wijzigen</button>
                    </div>
                    <div className="w-full border-b border-gray-400 pt-4 py-6"></div>

                    {contextStorage.order ? Object.entries(contextStorage.order).map(([key, object]) =>
                        <OrderRecord index={key} data={object} completion={object.completion} />) : ''}

                    <p className="font-bold text-lg ml-auto mt-4 text-right">Totaal € <span id="g_tag_ordertotal">{contextStorage.calculateTotal()}</span> </p>
                    <button className={` bg-primaryBlue bg w-full py-4 text-center mt-6 text-white rounded-md transition-all font-medium text-xl ${nextStepValidation() ? 'bg-primaryBlue hover:bg-cyan-800' : ' bg-gray-300'}`} disabled={nextStepValidation() ? false : true} onClick={handleProgress}>Volgende</button>
                </div>

            </div>
        </>
    )


}

export default SelectService