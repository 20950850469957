import React, { useState, useContext, useEffect, useRef } from "react";
import { SiteContext } from "../../contexts/SiteContext";
import { gsap } from 'gsap'
import axios from "axios";

const PopupService = () => {
    let { gLoader } = useRef(null)
    const objectID = localStorage.getItem('activeOrder') || 0
    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const contextStorage = useContext(SiteContext)
    const [selectedService, setSelectedService] = useState([]);
    const currObj = contextStorage.order[objectID ? objectID : 0]
    const tl = gsap.timeline()
    let { fadeIn } = useRef(null)

    useEffect(() => {
        console.log('Fetching')
        tl.to(fadeIn, {
            duration: 0.5, y: 0, ease: "power4.out"
        })
        gsap.to(gLoader, {
            opacity: 0, duration: .8, stagger: {
                amount: .6
            }, repeat: -2
        })

        axios.get(`https://deinze-kajakt-api.vercel.app/api/services/category/${contextStorage.category}/${process.env.REACT_APP_LOCATION}`).then(async res => {
            console.log(res.data)
            const filtered_services = res.data
            const serv_arr = []
            for (const service of filtered_services) {
                const status = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/calendar/services-open/${currObj.date}/${process.env.REACT_APP_LOCATION}/${service.id}`)
                const payload = service
                payload.enabled = status.data
                console.log(status.data)
                serv_arr.push(payload)
            }

            const sss = parseInt(filtered_services[0].price_per_vessel) > 0  ? filtered_services.sort((a, b) => a.price_per_vessel - b.price_per_vessel) : filtered_services.sort((a, b) => a.priceAdult - b.priceAdult)

            setSelectedService(sss || res.data)
        }).catch(err => console.log(err))
    }, []);

    useEffect(() => console.log(selectedService), [selectedService])

    const handleClick = (param) => {
        console.log(currObj)
        const tmp = currObj
        tmp.img = param.img
        tmp.serviceDetail = param.name
        tmp.priceAdult = param.priceAdult
        tmp.priceChild = param.priceChild
        tmp.price_per_vessel = param.price_per_vessel
        tmp.duration = param.duration
        tmp.serviceID = param.id
        contextStorage.setOrder((prevState) => ({
            ...prevState,
            [objectID ? objectID : 0]: tmp
        }))
        contextStorage.setPopupCounter(2)
    }

    const isServiceOpenToday = (service) => {
        let doShow = true
        if (service.active && (service.active === false || service.active === "false")) doShow = false
        if (service.days) {
            // console.log(service.days)
            const today = new Date(contextStorage.order[objectID ? objectID : 0].date).getDay()
            // console.log('today', new Date(contextStorage.order[objectID ? objectID : 0].date))
            // console.log('today index', today)
            const dayIndex = today == 0 ? 6 : today - 1
            // console.log('dayIndex', dayIndex)
            const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
            // console.log('serv day')
            // console.log('serv day', service.days[days[dayIndex]])
            // console.log('serv dayss', !service.days[days[dayIndex]])
            if (!service.days[days[dayIndex]]) doShow = false
        }
        // console.log(service)

        // Checking hours availability
        // if(doShow) {
        //     const res = await axios.get(`${process.env.REACT_APP_BASE_URL_DEV}/api/calendar/services-open/${currObj.date}/${process.env.REACT_APP_LOCATION}/${service.id}`)
        //     console.log(res.data)
        //     if(res.data === false) doShow = false
        // }

        // console.log('END DOSHOW', service.name, doShow)
        return doShow
    }

    return (
        <div className="fixed inset-0 flex lg:justify-center items-center bg-black bg-opacity-60 lg:w-screen lg:h-screen overflow-x-scroll z-[1000] px-4">
            <div ref={el => fadeIn = el} className="flex flex-col bg-slate-100 p-7 rounded-md transform -translate-y-20">
                <div className="flex lg:justify-between items-center mb-6">
                    <h2 className=" order-2 lg:order-1 text-xl ml-7 lg:ml-0 font-bold">{selectedService.length ? currObj.service + ' - ' + (contextStorage.keepDate ? new Date(contextStorage.keepDate).toLocaleDateString("nl-BE", options) : new Date(currObj.date).toLocaleDateString("nl-BE", options)) : "Een ogenblik geduld"}</h2>
                    <div className="order-1 lg:order-2 bg-gray-300 rounded-full w-7 h-7 flex justify-center items-center hover:bg-primaryBlue hover:text-white lg:ml-16 cursor-pointer" onClick={() => contextStorage.setPopupStatus(false)}>X</div>
                </div>
                <div className=" max-w-xs lg:max-w-4xl">
                    <div className={`grid grid-flow-col gap-4 ${selectedService.length && " overflow-x-scroll"}`}>
                        {selectedService.length ? selectedService.map((item, index) =>
                            isServiceOpenToday(item) && item.enabled ? <div key={index} className="flex flex-col rounded-md w-72 group shadow-lg overflow-hidden cursor-pointer" onClick={() => handleClick(item)}>
                                <div className="h-44 relative overflow-hidden">
                                    <img src={item.img} alt="" className=" h-full w-full absolute inset-0 object-cover group-hover:scale-125 transition-all duration-500" />
                                </div>
                                <div className=" flex flex-col py-5 px-3 justify-center w-full items-center bg-white">
                                    <h3 className=" font-bold text-xl mb-2">{item.name}</h3>
                                    <div className="flex gap-x-1 flex-col">
                                        <p className=" text-center">{`${item.priceAdult ? "Volwassene € " + item.priceAdult : ""} ${item.priceChild ? "Kind € " + item.priceChild : ""} ${item.priceChildY ? "Kind -12j € " + item.priceChildY : ""}`} </p>
                                    </div>
                                    <button className="px-4 py-2 border text-white border-primaryBlue rounded-md hover:bg-transparent hover:text-primaryBlue transition-all mt-3 w-full bg-primaryBlue" onClick={() => handleClick(item)}>Selecteren</button>
                                </div>
                            </div> : <div key={index} className="flex flex-col rounded-md w-72 group shadow-lg overflow-hidden opacity-40">
                                <div className="h-44 relative overflow-hidden">
                                    <img src={item.img} alt="" className=" h-full w-full absolute inset-0 object-cover group-hover:scale-125 transition-all duration-500" />
                                </div>
                                <div className=" flex flex-col py-5 px-3 justify-center w-full items-center bg-white">
                                    <h3 className=" font-bold text-xl mb-2">{item.name}</h3>
                                    <div className="flex gap-x-1 flex-col">
                                        <p className=" text-center">Niet beschikbaar</p>
                                    </div>
                                    <button className="pointer-events-none px-4 py-2 border text-white border-primaryBlue rounded-md hover:bg-transparent hover:text-primaryBlue transition-all mt-3 w-full bg-primaryBlue">Selecteren</button>
                                </div>
                            </div>
                        ) : <p ref={el => gLoader = el} className="text-2xl font-medium text-center my-6 lg:my-14">Beschikbare services worden opgehaald</p>}
                    </div>
                </div>
            </div>
        </div>
    )
};
export default PopupService;
