import React, { useEffect, useState, useContext } from "react";
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import bcImage from './../assets/images/map.png'
import { SiteContext } from "../contexts/SiteContext";
import OrderRecord from "./OrderRecord";
import { useParams } from "react-router";
import axios from "axios"

const Confirmation = () => {
    const contextStorage = useContext(SiteContext)
    let { objectID } = useParams();
    const currObj = contextStorage.order[objectID ? objectID : 0]
    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    useEffect(() => {
        console.log(currObj)
        console.log(contextStorage.order)
        contextStorage.setProgressCounter(4)
        contextStorage.setProgressCheck(4)
    }, [contextStorage]);


    // // Add Order test
    // useEffect(() => {
    //     console.log(contextStorage.order)
    //     axios.post("https://deinze-kajakt-api.vercel.app/api/orders/add", contextStorage.order).then(r => console.log(r.data))
    // }, [])

    const getCustomer = () => {
        let customer = {}
        Object.values(contextStorage.order).forEach(order => {
            if (order.consumer && order.consumer.email && order.consumer.email !== '') {
                console.log(order.consumer)
                customer = order.consumer
            }
        })
        return customer
    }

    return (
        <div className=" mx-auto flex flex-col lg:flex-row justify-center lg:py-32 pt-10 lg:pt-20 w-full">
            <div className="bg-slate-100 p-8 rounded-tr-xl rounded-tl-xl lg:rounded-xl flex-col w-full lg:w-3/5">
                <h2 className=" text-xl font-bold border-b border-gray-400 pb-4 mb-6">Bedankt voor uw reservatie</h2>
                <div className="flex flex-col lg:flex-row gap-6">
                    <div className="flex flex-col w-full lg:w-2/5">
                        <p className=" mb-6">We hebben uw reservatie <strong> succesvol ontvangen. </strong> We kijken
                            er naar uit u te ontvangen bij Deinze Kajakt!</p>
                        <p>Ter hoogte van Hellestraat 22 <br /> 9800 Deinze (Astene Sas) <br /> <a className=" underline hover:text-cyan-600"
                            target="_blank"
                            href=" mailto:info@deinzekajakt.be">info@deinzekajakt.be</a>
                            <br /> <a className=" underline hover:text-cyan-600" href="tel:09 4968966">09/496.89.66</a></p>
                        <button
                            className=" bg-primaryBlue bg w-full py-2 text-center text-white rounded-md hover:bg-cyan-800 transition-all text-md px-4 mb-3 mt-10"
                            onClick={() => window.location = 'https://deinzekajakt.be'}>Terug naar website
                        </button>
                        <button
                            className=" border border-primaryBlue bg w-full py-2 text-center rounded-md hover:bg-primaryBlue hover:text-white transition-all text-md px-4 mb-3"
                            onClick={() => window.location = "https://deinzekajakt.be/praktische-info/"}
                        >Praktische
                            informatie
                        </button>
                        <button
                            className=" border border-primaryBlue bg w-full py-2 text-center rounded-md hover:bg-primaryBlue hover:text-white transition-all text-md px-4 mb-3"
                            onClick={() => window.location = "https://deinzekajakt.be/veel-gestelde-vragen/"}
                        >Veel gestelde vragen
                        </button>
                    </div>
                    <div className=" w-full lg:w-3/5 relative h-56 lg:h-96">
                        {/* <img src={bcImage} className=" absolute inset-0 w-full h-full object-cover" alt="" /> */}
                        <iframe src="https://snazzymaps.com/embed/382654" width="100%" height="600px"
                            style={{ border: 'none', width: "100%", height: "100%" }}></iframe>
                    </div>
                </div>

            </div>
            <div className="w-6" />
            <div className="bg-slate-100 p-8 lg:rounded-xl rounded-br-xl rounded-bl-xl flex-col w-full lg:w-2/5">
                <h2 className=" text-xl font-bold border-b border-gray-400 pb-4 mb-6">Uw reservatie</h2>
                <div className="flex">
                    <div className="mr-12 font-bold">
                        <p></p>
                        <p>Voornaam</p>
                        <p>Naam</p>
                        <p>E-mailadres</p>
                        <p>GSM</p>
                        <p>Postcode</p>
                        {/*<p>Betaald</p>*/}
                    </div>
                    <div className=" mr-20 text-gray-500 text-sm">
                        <p className=" mb-1">{JSON.parse(localStorage.getItem('ordered_by')).firstname}</p>
                        <p className=" mb-1">{JSON.parse(localStorage.getItem('ordered_by')).lastname}</p>
                        <p className=" mb-1">{JSON.parse(localStorage.getItem('ordered_by')).email}</p>
                        <p className=" mb-1">{JSON.parse(localStorage.getItem('ordered_by')).phone}</p>
                        <p className=" mb-1">{JSON.parse(localStorage.getItem('ordered_by')).postal}</p>
                        {/*<p className=" mb-1">Betaalmethode hier</p>*/}
                    </div>
                </div>
                <div className="w-full border-b border-gray-400 pt-4"></div>
                <div className="flex flex-col">
                    {contextStorage.order ? Object.entries(contextStorage.order).map(([key, object]) =>
                        <OrderRecord index={key} data={object} completion={object.completion} />)
                        : ''}
                    <p className="font-bold text-lg ml-auto mt-4 text-right">Totaal
                        € {contextStorage.calculateTotal()} </p>

                </div>
            </div>
        </div>
    )


}

export default Confirmation