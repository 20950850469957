import React from "react";
import Logo from './../assets/images/Logo-IBgroup.png'
import BC from './../assets/images/bc-full.png'
import BG from './../assets/images/bg.jpg'

const Footer = () => {

    return (
        <div className=" bg-primaryBlue w-full pb-7 text-white font-light">
            <div className="h-64 w-full mb-10 overflow-hidden bg-cover z-50 footer-bg-img"></div>
            <div className="max-w-screen-2xl mx-auto grid lg:grid-cols-4 pb-4 lg:pb-20 px-6">
                <div>
                    <a href="/"><img src={Logo} alt="logo" className='block mb-6 w-32' /></a>
                    <p className=" text-xl">Deinze Kajakt behoort tot de IB-group.  Ontdek onze andere diensten op <a className=" underline" href="https://Kajakpunt.be">Kajakpunt.be</a> & <a className=" underline" href="https://Gentkajakt.be">Gentkajakt.be</a></p>
                    {/* <img src={BC} alt="bancontact icon" className=" w-36 mt-4 rounded-sm" /> */}
                </div>
                <div>
                    <h2 className=" text-xl font-semibold mt-7 lg:mt-0 mb-2 lg:mb-9">Sitemap</h2>
                    <ul className=" text-sm flex flex-col items-start">
                        <a target="_blank" className=" block mb-1 text-base border-b border-transparent hover:border-white transition-all" href="https://deinzekajakt.be/kajakken-op-de-leie/">Over ons</a>
                        <a target="_blank" className=" block mb-1 text-base border-b border-transparent hover:border-white transition-all" href="https://deinzekajakt.be/team-building/">Teambuilding</a>
                        <a target="_blank" className=" block mb-1 text-base border-b border-transparent hover:border-white transition-all" href="https://deinzekajakt.be/praktische-info/">Praktische info</a>
                    </ul>
                </div>
                <div>
                    <h2 className=" text-xl font-semibold mt-7 lg:mt-0 mb-2 lg:mb-9">Over ons</h2>
                    <ul className=" text-sm flex flex-col items-start">
                        <a target="_blank" className=" block mb-1 text-base border-b border-transparent hover:border-white transition-all" href="https://deinzekajakt.be/nieuws-evenementen/">Nieuws en Evenementen</a>
                        <a target="_blank" className=" block mb-1 text-base border-b border-transparent hover:border-white transition-all" href="https://deinzekajakt.be/veel-gestelde-vragen/">Veel gestelde vragen</a>
                        <a target="_blank" className=" block mb-1 text-base border-b border-transparent hover:border-white transition-all" href="https://deinzekajakt.be/contact/">Contact</a>
                    </ul>
                </div>
                <div>
                    <h2 className=" text-xl font-semibold mt-7 lg:mt-0 mb-2 lg:mb-9">Contacteer ons</h2>
                    <ul className=" text-sm flex flex-col items-start">
                        <a target="_blank" className=" block mb-1 text-base" href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2511.3642679669347!2d3.564357476662978!3d50.99094047170036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c36cf1b1a780ab%3A0x3bfae8e1659e6c93!2sHellestraat%2022%2C%209800%20Deinze!5e0!3m2!1snl!2sbe!4v1725992255828!5m2!1snl!2sbe">Ter hoogte van Hellestraat 22<br /> 9800 Deinze (Astene Sas)</a>
                        <a target="_blank" className=" block mb-1 text-base border-b border-transparent hover:border-white transition-all" href="mailto:info@deinzekajakt.be">Mail: info@deinzekajakt.be</a>
                        <a target="_blank" className=" block mb-1 text-base border-b border-transparent hover:border-white transition-all" href="tel:094968966">Tel: 04/496.89.66</a>
                        <p  className=" block mb-1 text-base border-b border-transparent hover:border-white transition-all">BTW: BE0779.527.929</p>
                    </ul>
                </div>
            </div>

            <div className="w-full border-t border-slate-600 mt-7 pt-7 px-6">
                <div className="max-w-screen-2xl mx-auto grid lg:grid-cols-4 font-medium">
                    <a href="https://onlinedesign.be" className=" hover:underline transition-all order-2 lg:order-1">© Online Design | 2022 Deinze Kajakt</a>
                    <div className=" order-2" />
                    <div className=" order-3" />
                    <a href="https://deinzekajakt.be/algemene-voorwaarden" className=" hover:underline transition-all mb-4 lg:mb-0 order-1 lg:order-4">Algemene voorwaarden</a>
                </div>
            </div>
        </div>
    )
};
export default Footer;
