import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/main.scss'
import './index.css';
import App from './App';
import 'tw-elements';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
      {localStorage.getItem('__nn_at__') && <div className="w-full bg-red-500 text-white flex items-center px-8 py-4 font-bold justify-between">
          <p>Administrator-modus</p>
          <div className="flex">
              <button className="border-0 mr-4" onClick={() => window.location.href = 'https://administratie.deinzekajakt.be/orders'}>Terug naar dashboard</button>
              <button className="border-0" onClick={() => {
                  localStorage.removeItem('__nn_at__')
                  window.location.reload()
              }}>Uitloggen</button>
          </div>
      </div>}
    <App />
  </>
);
