import React, { useContext, useEffect, useState } from "react";
import { SiteContext } from "../contexts/SiteContext";
import { AiOutlineEdit } from 'react-icons/ai'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { addMinutes } from 'date-fns'
import _ from "lodash";

const OrderRecord = ({ data, index }) => {

    console.log('orderRecord data', data)

    const objectID = localStorage.getItem('activeOrder') || 0
    const contextStorage = useContext(SiteContext)
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    const handleClick = () => {
        contextStorage.setTmpOrder(false)
        contextStorage.setProgressCounter(1)
        contextStorage.setOrder({ customer: {} })
    }

    const serviceString = (data) => {
        if (!data.date || !data.time) return
        // console.log(data)
        const timesplit = data.time.split(':')
        const starttime = new Date()
        starttime.setHours(timesplit[0])
        starttime.setMinutes(timesplit[1])
        const endtime = addMinutes(starttime, parseInt(data.duration))
        // console.log(starttime)
        // console.log(endtime)
        const starthour = starttime.getHours().toString().length === 2 ? starttime.getHours() : `${starttime.getHours().toString()}`
        const startminutes = starttime.getMinutes().toString().length === 2 ? starttime.getMinutes() : `${starttime.getMinutes().toString()}0`
        const endhour = endtime.getHours().toString().length === 2 ? endtime.getHours() : `${endtime.getHours().toString()}`
        const endminutes = endtime.getMinutes().toString().length === 2 ? endtime.getMinutes() : `${endtime.getMinutes().toString()}0`
        // console.log(`${endtime.getMinutes().toString()}0`)
        const string = `${data.serviceDetail} | ${starthour}:${startminutes} - ${endhour}:${endminutes}`
        return string
    }

    const formatOptions = () => {
        if (!contextStorage.allOptions) return ''
        // (parseFloat(option.price * value))
        let html = ''
        // console.log(data)
        // console.log(data.options)
        Object.entries(data.options).forEach(([key, value]) => {
            const option = contextStorage.allOptions.find(opt => opt.id === key)
            if (value > 0 && option) html += '<li>' + value + 'x ' + option.name + '</li>'
        })
        return html
    }

    const calculateSubtotal = () => {
        console.log("calculateSubtotal FOR " + index)
        if (!contextStorage.services || !contextStorage.settings) return
        console.log("calculateSubtotal serv and sett")
        const s_service = contextStorage.services.find(s => s.id === data.serviceID)
        if (!s_service) return
        console.log("calculateSubtotal s_serv")
        let pre_discount_sub = 0
        if (data.vessel_amount && data.vessel_amount > 0) {
            pre_discount_sub = (parseInt(data.vessel_amount) * parseFloat(data.price_per_vessel)) + (parseFloat(data.optionsTotal) ? parseFloat(data.optionsTotal) : 0)
        } else {
            pre_discount_sub = (parseInt(data.adult) * parseFloat(data.priceAdult)) + (parseInt(data.child) * parseFloat(data.priceChild)) + (parseFloat(data.optionsTotal) ? parseFloat(data.optionsTotal) : 0)
        }

        // console.log(contextStorage.settings)
        const totalPeople = parseInt(data.adult) + parseInt(data.child)
        let appliedDiscount = 0
        if (totalPeople >= 10) appliedDiscount = contextStorage.settings.group_discount_10
        if (totalPeople >= 25) appliedDiscount = contextStorage.settings.group_discount_25
        if (totalPeople >= 50) appliedDiscount = contextStorage.settings.group_discount_50

        const discount_factor = (100 - appliedDiscount) / 100

        const sub = (s_service.group_price ? (pre_discount_sub * discount_factor) : pre_discount_sub).toFixed(2)
        const discount_amount = s_service.group_price ? Math.round(pre_discount_sub * (1 - discount_factor)) : false




        // console.log(appliedDiscount)
        // console.log(totalPeople)
        // console.log(discount_factor)

        // console.log(data.subtotal != sub)
        // console.log(data.subtotal)
        // console.log(sub)
        // console.log(index)
        // console.log('discount_amount local', discount_amount, 'discount_amount order', contextStorage.order[index].discount_amount, 'isEqual', discount_amount === contextStorage.order[index].discount_amount)
        // console.log('no ex', !contextStorage.order[index].discount_amount)
        // console.log('no eq', contextStorage.order[index].discount_amount !== discount_amount)

        // console.log('test', contextStorage.order[index].stes)
        // console.log('test', contextStorage.order[index].subtotal)
        // console.log('test', contextStorage.order[index].discount_amount)


        if (contextStorage.order[index].subtotal === undefined || !_.isEqual(contextStorage.order[index].subtotal, sub)) contextStorage.setOrder({ ...contextStorage.order, [index]: { ...contextStorage.order[index], subtotal: sub } })
        if (contextStorage.order[index].discount_amount === undefined || contextStorage.order[index].discount_amount !== discount_amount) contextStorage.setOrder({ ...contextStorage.order, [index]: { ...contextStorage.order[index], discount_amount } })
        return sub
    }

    return (
        data.completion && (
            <div className="flex flex-col mt-7">
                <div className="flex flex-col  border-b border-gray-400 py-4">
                    <div className="flex flex-row justify-between text-sm lg:text-lg">
                        <div className="flex flex-row">
                            <div className=" h-16 w-16 xl:h-20 xl:w-20 mr-2 xl:mr-6">
                                <img src={data.img} className="rounded-md h-full object-cover" alt="" />
                            </div>
                            <div className="flex flex-col">
                                <h3 className="font-bold">{serviceString(data)}</h3>
                                <p className="">{new Date(data.date).toLocaleDateString("nl-BE", options)} </p>
                                {data.adult > 0 ? <p>{data.adult}x volwassene</p> : ''}
                                {data.child > 0 ? <p>{data.child}x kind</p> : ''}
                                {/*{data.childY > 0 ? <p>{data.childY} x kind -12 jaar</p> : ''}*/}
                                {data.options && <ul dangerouslySetInnerHTML={{ __html: formatOptions() }} />}
                            </div>
                        </div>
                        <div className="flex flex-col xl:ml-16">
                            {/*<p className=" font-bold">€ {data.adult * data.priceAdult + data.child * data.priceChild + data.childY * data.priceChildY}</p>*/}
                            <p className=" font-bold">&nbsp;</p>
                            <p className=" font-bold">&nbsp;</p>
                            {data.adult > 0 ? <p className=" font-bold">€ {data.priceAdult * data.adult} </p> : ''}
                            {data.child > 0 ? <p className=" font-bold">€ {data.priceChild * data.child}</p> : ''}
                            {data.options && Object.values(data.options).length > 0 ? <p className=" font-bold whitespace-nowrap">€ {data.optionsTotal}</p> : ''}
                        </div>
                    </div>
                    <div className="flex mt-3 items-center">
                        {/*<button className=" py-1 border border-transparent hover:border-primaryBlue px-2 font-medium rounded-md mr-4 text-primaryBlue transition-all"> <AiOutlineEdit className=" inline-block text-lg mb-1 mr-1" /> Bewerken</button>*/}
                        {contextStorage.progressCounter != 4 && <button
                            className=" py-1 border border-transparent hover:border-red-500 px-2 font-medium rounded-md text-red-500 transition-all"
                            onClick={() => contextStorage.removeOrder(index)}> <RiDeleteBin6Line className=" inline-block text-lg mb-1 mr-1" />Verwijderen
                        </button>}

                        <p className="font-bold text-md ml-auto">Subtotaal € {calculateSubtotal()} {data.discount_amount ? '(- € ' + data.discount_amount + ' groepskorting)' : ''}</p>
                    </div>
                </div>
            </div>)
    )
};
export default OrderRecord;
