import React, {useState, useContext, useEffect, useRef} from "react";
import Counter from "./Counter";
import {AiFillExclamationCircle} from 'react-icons/ai';
import {BsFillInfoCircleFill} from 'react-icons/bs'
import {SiteContext} from "../../contexts/SiteContext"
import {gsap} from 'gsap'
import axios from "axios";
import ExtrasCounter from "./ExtrasCounter";

const PopupDetails = () => {
    const objectID = localStorage.getItem('activeOrder') || 0
    let options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
    const [check, setCheck] = useState(false);
    const [extras, setExtras] = useState(false);
    const contextStorage = useContext(SiteContext)
    const currObj = contextStorage.order[objectID ? objectID : 0]
    const [items, setItems] = useState([]);
    const tl = gsap.timeline()
    let {fadeIn} = useRef(null)
    const [hasVessel, setHasVessel] = useState(true)

    useEffect(() => {
        tl.to(fadeIn, {
            duration: 0.5, y: 0, ease: "power4.out"
        })

        axios.get("https://deinze-kajakt-api.vercel.app/api/options/service/" + contextStorage.order[objectID ? objectID : 0].serviceID).then(res => {
            const filtered = res.data.filter(option => !option.soft_deleted)
            if (!filtered.length) { // In case no options are available, next step AND set completed=true
                handleClick()
            }
            setExtras(filtered)
        }).catch(err => console.log(err))

        // axios.get("https://deinze-kajakt-api.vercel.app/api/vessels/service/" + contextStorage.order[objectID ? objectID : 0].serviceID).then(res => {
        //     // console.log(res)
        //     const cats = res.data
        //
        //     if (cats === 'no vessel') {
        //         setHasVessel(false)
        //     }
        //
        // }).catch(err => console.log(err))

    }, []);

    useEffect(() => {
        setCheck(currObj.jacket < (currObj.adult + currObj.child) ? false : true)
    }, [currObj.jacket]);

    const handleClick = () => {
        const copy = contextStorage.order
        copy[objectID].completion = true
        contextStorage.setOrder({...copy})
        setItems(items => [...items, currObj])
        contextStorage.setSelectionFinished(true)
        contextStorage.setPopupCounter(5)
    }

    useEffect(() => {
        if (items.length > 0) {
            contextStorage.setPopupStatus(false)
            contextStorage.setTmpOrder(true)
            contextStorage.setSelectionFinished(true)
        } else {
            console.log('Array is LEEG')
        }
    }, [items]);

    return (
        <div
            className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-60 w-screen h-screen z-[1000]">
            {extras &&
            <div ref={el => fadeIn = el} className="flex flex-col bg-slate-50 p-7 rounded-md transform -translate-y-20">
                <div className="flex justify-between items-center mb-6">
                    <h2 className=" text-xl font-bold">{currObj.service + ' - ' + new Date(currObj.date).toLocaleDateString("nl-BE", options)}</h2>
                    <div
                        className="bg-gray-300 rounded-full w-7 h-7 flex justify-center items-center cursor-pointer hover:bg-primaryBlue hover:text-white ml-16"
                        onClick={() => contextStorage.setPopupStatus(false)}
                    >X
                    </div>
                </div>

                <div className="flex flex-col">

                    {extras && extras.length ? extras.map((extra, key) => <ExtrasCounter key={key} options={extras}
                                                                                         price={parseFloat(extra.price)}
                                                                                         label={extra.name}
                                                                                         type={extra.id}
                                                                                         dataObject={extra}
                                                                                         isOption={true}/>) : 'Geen opties voor deze dienst'}

                    {/*<Counter label="Helm" type={el.type} capacity={el.capacity} disabled={(currObj.adult + currObj.child + currObj.childY) == currObj.capacity ? 'gelijk' : (currObj.adult + currObj.child + currObj.childY) > currObj.capacity ? 'te veel mensen' : "te weinig mensen"} />*/}
                    {/* <Counter price={15.00} label="Reddingsvest" type="jacket" /> */}
                    {extras && extras.length ? <div className="flex items-center mb-5 mt-2">
                        <BsFillInfoCircleFill className=" text-xl border-none mr-4 text-sky-500"/>
                        <p className="text-sky-500 font-medium max-w-sm">Voeg het aantal van iedere optie toe.</p>
                    </div> : ''}
                    {/* {check ? '' : <div className="flex items-center mb-5 mt-2">
                        <AiFillExclamationCircle className=" text-xl text-red-500 teai-xl border-none mr-4" />
                        <p className="text-red-500 font-medium max-w-sm">Een zwemvest is voor iedereen verplicht <strong>
                            ({currObj.seatsNeeded} stuks) </strong></p>
                    </div>} */}
                </div>

                <div className="flex">
                    <button
                        className={` bg w-full py-4 text-center mt-6 hover:text-white rounded-md transition-all font-medium text-xl border border-primaryBlue text-primaryBlue hover:bg-primaryBlue mr-3`}
                        onClick={() => contextStorage.setPopupCounter(2)}>Vorige
                    </button>
                    <button
                        className={` bg-primaryBlue bg w-full py-4 text-center mt-6 text-white rounded-md transition-all font-medium text-xl`}
                        onClick={() => handleClick()}>Activiteit toevoegen
                    </button>
                </div>
            </div>}
        </div>
    )
};
export default PopupDetails;
