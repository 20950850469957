import React, { useState, useContext, useEffect, useRef } from "react";
import Counter from "./Counter";
import { SiteContext } from "../../contexts/SiteContext"
import { BsFillInfoCircleFill } from 'react-icons/bs'
import { AiFillExclamationCircle, AiFillCheckCircle } from 'react-icons/ai'
import { gsap } from 'gsap'
import axios from "axios";

const PopupVessel = () => {
    const objectID = localStorage.getItem('activeOrder') || 0
    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const supVessels = [{ label: "Enkele sup", type: "sup_1p", capacity: 1 }, { label: "2 persoons sup", type: "sup_2p", capacity: 2 }]
    const kayakVessels = [{ label: "Enkele kajak", type: "kayak_1p", capacity: 1 }, { label: "2 persoonskajak", type: "kayak_2p", capacity: 2 }, { label: "3 persoonskajak", type: "kayak_3p", capacity: 3 }]
    const contextStorage = useContext(SiteContext)
    const [check, setCheck] = useState(false);
    const [vessels, setVessels] = useState(false);
    const currObj = contextStorage.order[objectID ? objectID : 0]
    const tl = gsap.timeline()
    let { fadeIn } = useRef(null)

    useEffect(() => {
        tl.to(fadeIn, {
            duration: 0.5, y: 0, ease: "power4.out"
        })
        // axios.get("https://deinze-kajakt-api.vercel.app/api/vessels/service/"+contextStorage.order[objectID ? objectID : 0].serviceID).then(res => {
        //     // console.log(res)
        //     const cats = res.data
        //
        //     if(cats === 'no vessel'){
        //         contextStorage.setPopupCounter(4)
        //     }else{
        //         cats.sort((a, b) => a.name - b.name)
        //         setVessels(cats) // TODO MAKE PLURAL
        //         console.log(cats)
        //     }
        //
        // }).catch(err => console.log(err))

    }, []);

    useEffect(() => {
        // console.log(currObj.service)
    }, [contextStorage]);

    const handleClick = (date) => {
        contextStorage.setPopupCounter(4)
    }

    useEffect(() => {
        setCheck((currObj.adult + currObj.child + currObj.childY) == currObj.capacity || (currObj.adult + currObj.child + currObj.childY) < currObj.capacity ? true : false)
    }, [contextStorage.order]);


    return (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-60 w-screen h-screen z-[1000]">
            <div ref={el => fadeIn = el} className="flex flex-col bg-slate-50 p-7 rounded-md transform -translate-y-20">
                <div className="flex justify-between items-center mb-6">
                    <h2 className=" text-xl font-bold">{currObj.serviceDetail + ' - ' + new Date(currObj.date).toLocaleDateString("nl-BE", options)}</h2>
                    <div className="bg-gray-300 rounded-full w-7 h-7 flex justify-center items-center cursor-pointer hover:bg-primaryBlue hover:text-white ml-16" onClick={() => contextStorage.setPopupStatus(false)}>X</div>
                </div>
                <div className="flex flex-col popup-details-datepicker">

                    <h2 className=" text-lg mb-6">Selecteer uw gewenste vaartuig</h2>


                    {/*{currObj.service == "Suppen" ? supVessels.map(el =>*/}
                    {/*    <Counter label={el.label} type={el.type} capacity={el.capacity} disabled={(currObj.adult + currObj.child + currObj.childY) == currObj.capacity ? 'gelijk' : (currObj.adult + currObj.child + currObj.childY) > currObj.capacity ? 'te veel mensen' : "te weinig mensen"} />*/}
                    {/*) : kayakVessels.map(el =>*/}
                    {/*    <Counter label={el.label} type={el.type} capacity={el.capacity} disabled={(currObj.adult + currObj.child + currObj.childY) == currObj.capacity ? 'gelijk' : (currObj.adult + currObj.child + currObj.childY) > currObj.capacity ? 'te veel mensen' : "te weinig mensen"} />*/}
                    {/*)}*/}

                    {vessels && vessels.map((vessel, key) => <Counter isVessel={true} key={key} label={vessel.name} type={vessel.id} capacity={vessel.capacity} disabled={(currObj.adult + currObj.child) == currObj.capacity ? 'gelijk' : (currObj.adult + currObj.child) > currObj.capacity ? 'te veel mensen' : "te weinig mensen"} />)}

                    {(currObj.adult + currObj.child ) == currObj.capacity ?
                        <div className="flex items-center mb-5 mt-2">
                            <AiFillCheckCircle className=" text-green-500 text-xl border-none mr-4" />
                            <p className="text-green-500 font-medium max-w-sm">Aantal plaatsen en personen komen overeen</p> </div> :
                        (currObj.adult + currObj.child) > currObj.capacity ?
                            <div className="flex items-center mb-5 mt-2">
                                <AiFillExclamationCircle className=" text-xl border-none mr-4 text-red-500" />
                                <p className=" text-red-500 font-medium max-w-sm">Niet alle personen hebben een vaartuig. U moet nog {(currObj.adult + currObj.child + currObj.childY) - currObj.capacity} plaatsen voorzien.</p>
                            </div> :
                            <div className="flex items-center mb-5 mt-2">
                                <BsFillInfoCircleFill className=" text-xl mr-4 text-sky-500" />
                                <p className="text-sky-500 font-medium max-w-sm">Voeg nog {contextStorage.order[objectID ? objectID : 0].seatsNeeded - contextStorage.order[objectID ? objectID : 0].capacity} personen toe aan een vaartuig</p>
                            </div>}
                </div>
                <div className="flex">
                    <button className={` bg w-full py-4 text-center mt-6 hover:text-white rounded-md transition-all font-medium text-xl border border-primaryBlue text-primaryBlue hover:bg-primaryBlue mr-3`} onClick={() => contextStorage.setPopupCounter(2)}>Vorige</button>
                    <button className={`bg-primaryBlue bg w-full py-4 text-center mt-6 text-white rounded-md transition-all font-medium text-xl ${check ? ' bg-primaryBlue hover:bg-cyan-800' : ' bg-gray-300'}`}
                        disabled={check ? false : true} onClick={handleClick}>Volgende</button>
                </div>
            </div>
        </div>
    )
};
export default PopupVessel;
