import React, { useState, useEffect, useContext } from "react";
import { SiteContext } from "../../contexts/SiteContext"
import { useParams } from "react-router";
import _ from 'lodash';
import axios from "axios";

const ExtrasCounter = ({ label, price, type, capacity, disabled, options, timeslot, dataObject }) => {
    const objectID = localStorage.getItem('activeOrder') || 0
    const contextStorage = useContext(SiteContext)
    const currObj = contextStorage.order[objectID ? objectID : 0]

    // useEffect(() => {
    //     console.log(capacity)
    // }, []);

    // const [services, setServices] = useState(false)
    //
    // useEffect(() => {
    //     axios.get("https://deinze-kajakt-api.vercel.app/api/services").then(res => {
    //         console.log(res.data)
    //         setServices(res.data)
    //     }).catch(err => console.log(err))
    // }, []);
    //
    // console.log(`<Counter type={${type}} />`)

    useEffect(() => {
        const tmp = currObj
        tmp.options = currObj.options || {}

        contextStorage.setOrder((prevState) => ({
            ...prevState,
            [objectID ? objectID : 0]: tmp
        }))
    }, []);


    const handleIncrease = (type) => {
        const currentOptions = currObj.options
        console.log(currentOptions)

        if(currentOptions[type]){ // Option already added
            let tmp = currentOptions[type]
            tmp += 1
            currentOptions[type] = tmp
        }else{ // Option yet to be added
            currentOptions[type] = 1
        }

        console.log('--')
        console.log(currentOptions[type])
        console.log(options)
        console.log('--')

        let extrasTotalPrice = 0
        Object.entries(currentOptions).forEach(([key, value]) => {
            const option = options.find(opt => opt.id === key)
            extrasTotalPrice += parseFloat(option.price) * value
        })
        console.log(extrasTotalPrice)

        contextStorage.setOrder({...contextStorage.order, [objectID]: {...contextStorage.order[objectID], options: currentOptions}})
        contextStorage.setOrder({...contextStorage.order, [objectID]: {...contextStorage.order[objectID], optionsTotal: extrasTotalPrice}})
    }

    const handleDecrease = (type) => {
        const currentOptions = currObj.options
        console.log(currentOptions)

        if(currentOptions[type] && currentOptions[type] > 0){ // Option already added
            let tmp = currentOptions[type]
            tmp -= 1
            currentOptions[type] = tmp
        }else{ // Option yet to be added
            currentOptions[type] = 0
        }

        console.log('--')
        console.log(currentOptions[type])
        console.log(options)
        console.log('--')

        let extrasTotalPrice = 0
        Object.entries(currentOptions).forEach(([key, value]) => {
            const option = options.find(opt => opt.id === key)
            extrasTotalPrice += parseFloat(option.price) * value
        })
        console.log(extrasTotalPrice)

        contextStorage.setOrder({...contextStorage.order, [objectID]: {...contextStorage.order[objectID], options: currentOptions}})
        contextStorage.setOrder({...contextStorage.order, [objectID]: {...contextStorage.order[objectID], optionsTotal: extrasTotalPrice}})
    }


    useEffect(() => {
        // const tmp = currObj
        // tmp.seatsNeeded = currObj.child + currObj.childY + currObj.adult
        // contextStorage.setOrder((prevState) => ({
        //     ...prevState,
        //     [objectID ? objectID : 0]: tmp
        // }))
    }, [currObj.adult, currObj.child, currObj.childY]);


    useEffect(() => {
        contextStorage.setSeatsCapRatio(currObj.capacity - currObj.seatsNeeded)
    }, [currObj.capacity]);

    return (
        <div className="flex justify-between mb-5">
            <p className=" mr-5 lg:mr-14 font-bold text-lg">{label}</p>
            <div className={`flex justify-between items-center ${price ? ' w-[11rem] lg:w-[13rem]' : ' w-auto'}`}>
                <div className={`flex h-10 bg-white rounded-md overflow-hidden border border-gray-300`}>
                    <div className={`h-full w-8 flex justify-center items-center px-3 text-white text-xl bg-primaryBlue hover:bg-cyan-800 cursor-pointer`}

                         onClick={() => handleDecrease(type, capacity, disabled)}>-</div>

                    <div className="w-16 h-full flex justify-center items-center">{contextStorage.order[objectID].options && contextStorage.order[objectID].options[type] ? contextStorage.order[objectID].options[type] : 0}</div>

                    <div className={`h-full w-8 flex justify-center items-center px-3 text-white text-xl bg-primaryBlue hover:bg-cyan-800 cursor-pointer bg-primaryBlue"}`}
                         onClick={() => handleIncrease(type)}>+</div>

                    {/*{type != 'adult' && type != 'child' && type != 'childY' && contextStorage.order[objectID].seatsNeeded + ' : '}*/}
                    {/*{type != 'adult' && type != 'child' && type != 'childY' && contextStorage.order[objectID].capacity}*/}
                </div>
                {price && <p className=" ml-2 lg:ml-0 font-bold">€ {contextStorage.order[objectID].options && contextStorage.order[objectID].options[type] ? contextStorage.order[objectID ? objectID : 0].options[type] * price : 0}</p>}
            </div>
        </div>
    )
};

export default ExtrasCounter;
