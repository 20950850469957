import React, { useState, useContext, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { addDays } from 'date-fns';
import Counter from "./Counter";
import { AiFillExclamationCircle } from 'react-icons/ai';
import { BsFillInfoCircleFill } from 'react-icons/bs'
import { SiteContext } from "../../contexts/SiteContext"
import { gsap } from 'gsap'
import Locale from "../Locale";
import axios from "axios";

const PopupDetails = () => {
    const objectID = localStorage.getItem('activeOrder') || 0
    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const contextStorage = useContext(SiteContext)
    const [startDate, setStartDate] = useState(new Date());
    const [isDisabled, setIsDisabled] = useState(false);
    const [timeSlots, setTimeSlots] = useState();
    const [selectedTime, setSelectedTime] = useState('Kies uur');
    const [service, setService] = useState(false);
    const [seatsAvailable, setSeatsAvailable] = useState(0);
    const [excludedDates, setExcludedDates] = useState(false)
    const arr = [{ label: "Kinderen", type: "child", price: 13 }, { label: "Volwassenen", type: "adult", price: 18 }]
    const currObj = contextStorage.order[objectID ? objectID : 0]
    const tl = gsap.timeline()
    let { fadeIn } = useRef(null)

    useEffect(() => {
        const tmp = currObj
        if (selectedTime) tmp.time = selectedTime
        contextStorage.setOrder((prevState) => ({
            ...prevState,
            [objectID ? objectID : 0]: tmp
        }))
        // console.log(tmp)

        axios.get(`${process.env.REACT_APP_BASE_URL}/api/calendar/timeslots/${tmp.date}/${process.env.REACT_APP_LOCATION}/${contextStorage.order[objectID ? objectID : 0].serviceID}`).then(data => {
            // console.log(data)
            let tmp = data.data
            console.log("tmp", tmp)
            const realSlots = []
            tmp.map(el => {
                const payload = el
                console.log(el)
                if (el.value != null && el.slots && el.slots > 0) { // Filtering out slots with 0 seats
                    el.label = el.value + ' (' + el.slots + (el.is_whole_vessel ? ' beschikbaar)' : ' plaatsen)')
                    realSlots.push(el)
                }
            })
            if(realSlots.length > 0) setTimeSlots(realSlots)
            // console.log(tmp)
        })

        axios.get("https://deinze-kajakt-api.vercel.app/api/services/" + contextStorage.order[objectID ? objectID : 0].serviceID).then(res => {
            console.log(res)
            setService(res.data)
        }).catch(err => console.log(err))

        tl.to(fadeIn, {
            duration: 0.5, y: 0, ease: "power4.out"
        })
    }, []);

    useEffect(() => {
        axios.get('https://deinze-kajakt-api.vercel.app/api/calendar/deinze').then(r => {
            // console.log(r.data)
            const formattedDates = []
            r.data.forEach(entry => formattedDates.push(new Date(entry.data.time_string)))
            setExcludedDates(formattedDates)
        })
    }, [])

    useEffect(() => {
        if (contextStorage.date != null != undefined)
            setStartDate(new Date(currObj.date))
    }, [contextStorage]);

    const handleChange = (date) => {
        setStartDate(date)
        const tmp = currObj
        tmp.date = date.getTime()
        contextStorage.setOrder((prevState) => ({
            ...prevState,
            [objectID ? objectID : 0]: tmp
        }))
    }

    useEffect(() => {
        timeSlots && setSelectedTime(timeSlots[0])
    }, [timeSlots]);

    const handleSelect = (time) => {
        console.log(time)
        const tmp = currObj
        tmp.time = time.value
        setSeatsAvailable(time.slots)
        tmp.seatsAvailable = time.slots
        contextStorage.setOrder((prevState) => ({
            ...prevState,
            [objectID ? objectID : 0]: tmp
        }))
        setSelectedTime(time)
    }

    useEffect(() => {
        if (!selectedTime) return
        console.log(seatsAvailable)
        console.log(selectedTime)

        const tmp = currObj
        tmp.time = selectedTime?.value
        tmp.seatsAvailable = selectedTime.slots

        contextStorage.setOrder((prevState) => ({
            ...prevState,
            [objectID ? objectID : 0]: tmp
        }))
    }, [seatsAvailable, selectedTime]);

    const handleClick = () => {
        console.log('HANDLECLICK')
        if (currObj.adult > 0 || currObj.child > 0 || currObj.vessel_amount > 0) {
            const tmp = currObj
            tmp.choseTimeAndSeats = true
            contextStorage.setOrder((prevState) => ({
                ...prevState,
                [objectID ? objectID : 0]: tmp
            }))
            contextStorage.setPopupCounter(4)
        }
    }

    const seatsValidation = () => {
        // console.log('seatsValidation')
        console.log(service)
        // NOTE that false is returned when all conditions are met, this is because the return value is used for a disabled prop
        if(service && service.price_per_vessel && service.price_per_vessel > 0){ // IF WHOLE VESSEL
            console.log('seatsValidation', 'is_whole_vessel')
            return currObj.seatsAvailable >= (currObj.vessel_amount);
        }else{ // IF REGULAR
            console.log('REGULAR x', currObj.adult + currObj.child)
            return currObj.seatsAvailable >= (currObj.adult + currObj.child);
        }
    }

    return (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-60 w-screen h-screen z-[1000] px-3">
            <div className="mx-3">
                <div ref={el => fadeIn = el} className="flex flex-col bg-slate-50 p-7 rounded-md transform -translate-y-20">
                    <div className="flex justify-between items-center mb-6">
                        <h2 className=" text-xl font-bold mr-5 lg:mr-0">{currObj.serviceDetail + ' - ' + new Date(currObj.date).toLocaleDateString("nl-BE", options)}</h2>
                        <div className="bg-gray-300 rounded-full w-7 h-7 flex justify-center items-center cursor-pointer hover:bg-primaryBlue hover:text-white lg:ml-16" onClick={() => contextStorage.setPopupStatus(false)}
                        >X</div>
                    </div>
                    <div className="flex flex-col popup-details-datepicker">
                        {/* <div className="flex justify-between mb-5">
                            <p className=" mr-14 font-bold text-lg">Datum</p>
                            <DatePicker
                                closeOnScroll={true}
                                selected={startDate}
                                onChange={(date) => handleChange(date)}
                                excludeDates={excludedDates}
                                minDate={new Date()}
                                locale={Locale}
                            />
                        </div> */}
                        <div className="flex justify-between mb-5">
                            <p className=" mr-14 font-bold text-lg lg:whitespace-nowrap">Selecteer uur</p>
                            {selectedTime &&
                                <Select
                                    isSearchable={false}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    value={selectedTime}
                                    isDisabled={isDisabled}
                                    name="color"
                                    options={timeSlots}
                                    onChange={(items) => handleSelect(items)}
                                />
                            }
                        </div>
                        {/*{selectedTime.value != undefined || selectedTime.value != null ?*/}
                        {/*    arr.map(el => <Counter timeslot={selectedTime || false} price={el.price} label={el.label} type={el.type} />) : ''}*/}
                        {service && (!service.price_per_vessel || service.price_per_vessel == 0) && <Counter timeslot={selectedTime || false} price={parseFloat(service.priceAdult)} label={service.priceChild ? "Volwassene" : "Personen"} type={"adult"} />}
                        {service && (!service.price_per_vessel || service.price_per_vessel == 0) && service.priceChild && service.priceChild != 0 && <Counter timeslot={selectedTime || false} price={parseFloat(service.priceChild)} label={"Kind"} type={'child'} />}
                        {service && (!!service.price_per_vessel && service.price_per_vessel != 0) && <Counter timeslot={selectedTime || false} price={parseFloat(service.price_per_vessel)} label={"Aantal"} type={'vessel_amount'} />}

                        {/*Other-location-notification*/}
                        {false && <div className="flex items-center mb-5 mt-2">
                            <BsFillInfoCircleFill className=" text-xl border-none mr-4 text-sky-500" />
                            <p className="text-sky-500 font-medium max-w-sm">Er zijn nog 15 plaatsen beschikbaar in Gent om 10:00 uur - Reserveer hier</p>
                        </div>}

                        {/*Out-of-places-notification*/}
                        {false && <div className="flex items-center mb-5 mt-2">
                            <AiFillExclamationCircle className=" text-red-500 text-xl border-none mr-4 rounded-full" />
                            <p className="text-red-500 font-medium max-w-sm">Geen plaatsen meer beschikbaar in Deinze voor dit tijdslot. Selecteer een ander tijdslot of datum </p>
                        </div>}
                    </div>
                    <div>
                        {seatsValidation() ? "" : <p className=" text-red-500">Geen plaats meer beschikbaar, mogelijk zijn er nog plaatsen vrij bij <a href="https://boeking.dentergemkajakt.be">Dentergem Kajakt</a></p>}
                    </div>
                    <div className="flex">
                        {
                            selectedTime &&
                            <>
                                <button className={` bg w-full py-4 text-center mt-6 hover:text-white rounded-md transition-all font-medium text-xl border border-primaryBlue text-primaryBlue hover:bg-primaryBlue mr-3`} onClick={() => contextStorage.setPopupCounter(1)}>Vorige</button>
                                <button className={`bg-primaryBlue bg w-full py-4 text-center mt-6 text-white rounded-md transition-all font-medium text-xl ${seatsValidation() && (currObj.adult > 0 || currObj.child > 0 || currObj.vessel_amount) ? 'bg-primaryBlue hover:bg-cyan-800' : ' bg-gray-300'}`}
                                    onClick={handleClick}
                                    disabled={seatsValidation() && (currObj.adult > 0 || currObj.child > 0 || currObj.vessel_amount > 0) ? false : true}>Volgende</button>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
};
export default PopupDetails;
