import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import _ from 'lodash';
import {format} from "date-fns";

const SiteContext = React.createContext();

const SiteProvider = (props) => {

    const cObjDefault = {
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        street: "",
        number: "",
        city: "",
        postal: ""
    }
    const [progressCounter, setProgressCounter] = useState(1);
    const [progressCheck, setProgressCheck] = useState(1);
    const [popupCounter, setPopupCounter] = useState(0);
    const [order, setOrder] = useState(JSON.parse(localStorage.getItem('orders')) || {
        0: {
            consumer: cObjDefault,
            date: new Date().getTime(),
            completion: false,
            location: process.env.REACT_APP_LOCATION,
            booker: localStorage.getItem('__nn_at__') ? 'administrator' : 'online'
        }
    });
    const [popupStatus, setPopupStatus] = useState(true);
    const [category, setCategory] = useState();
    const [seatsNeeded, setSeatsNeeded] = useState();
    const [seatsAvailable, setSeatsAvailable] = useState();
    const [seatsCapRatio, setSeatsCapRatio] = useState(0);
    const [tmpOrder, setTmpOrder] = useState(true);
    const [selectionFinished, setSelectionFinished] = useState((order[localStorage.getItem('activeOrder')] ? order[localStorage.getItem('activeOrder')].completion : order[localStorage.getItem('activeOrder')]) || true);
    const [voucher, setVoucher] = useState(false);
    const [errMessage, setErrMessage] = useState();
    const [infoMessage, setInfoMessage] = useState();
    const [successMessage, setSuccessMessage] = useState();
    const [disableVessels, setDisableVessels] = useState([]);
    const [allOptions, setAllOptions] = useState(false);
    const [appliedCouponCode, setAppliedCouponCode] = useState(false);
    const [couponDiscountAmount, setCouponDiscountAmount] = useState(false);
    const [keepDate, setKeepDate] = useState(undefined);

    const [couponData, setCouponData] = useState(false);
    const [groupDiscountData, setGroupDiscountData] = useState(false);


    const [services, setServices] = useState(false)
    const [settings, setSettings] = useState(false)

    useEffect(() => {
        axios.get("https://deinze-kajakt-api.vercel.app/api/services").then(res => {
            // console.log(res)
            setServices(res.data)
        }).catch(err => console.log(err))
        axios.get("https://deinze-kajakt-api.vercel.app/api/settings").then(res => {
            // console.log(res)
            setSettings(res.data)
        }).catch(err => console.log(err))
    }, []);

    useEffect(() => {
        console.log(order)
    }, [order]);

    // useEffect(() => {
    //     console.log(order.consumer)
    // }, [order.consumer]);

    useEffect(() => {
        axios.get("https://deinze-kajakt-api.vercel.app/api/options").then(res => {
            // console.log(res.data)
            setAllOptions(res.data)
        }).catch(err => console.log(err))
    }, []);

    // Add new order
    const addOrder = (skipredirect) => {
        const keyArr = []
        Object.keys(order).forEach(key => keyArr.push(parseInt(key)))
        const newKey = Math.max.apply(Math, keyArr) + 1
        const copy = order
        copy[newKey] = {
            consumer: cObjDefault,
            date: keepDate ? keepDate : new Date().getTime(),
            location: process.env.REACT_APP_LOCATION,
            booker: localStorage.getItem('__nn_at__') ? 'administrator' : 'online'
        }
        setOrder({...copy})
        if (!skipredirect) setProgressCounter(1)
        localStorage.setItem('orders', JSON.stringify(order))
        localStorage.setItem('activeOrder', newKey)
        return newKey
    }

    useEffect(() => {
        localStorage.setItem('orders', JSON.stringify(order))
    }, [order])

    const calculateTotal = (subtractCoupon) => {
        // console.log('Calculating Total')
        let total = 0
        let discountTotal = 0
        Object.values(order).forEach(ord => {
            // console.log(ord)
            if (ord.subtotal) {
                total += parseFloat(ord.subtotal)
            }

            // Coupon Code
            if (appliedCouponCode) {
                console.log('COUPONCODE APPLIED:', appliedCouponCode)
                console.log('order:', ord)
                if (appliedCouponCode.category === ord.category) {
                    console.log(`${appliedCouponCode.code} applies to order: ${ord.serviceDetail}`)
                    discountTotal += parseFloat(ord.subtotal)
                } else if (appliedCouponCode.category === 'all') {
                    console.log(`${appliedCouponCode.code} applies to order: ${ord.serviceDetail} (all)`)
                    discountTotal += parseFloat(ord.subtotal)
                }
            }
        })

        console.log('discountTotal', discountTotal)
        let discountAmount = false
        if (appliedCouponCode && discountTotal > 0) {
            discountAmount = discountTotal * parseFloat(appliedCouponCode.percentage) / 100
            console.log('discountAmount', discountAmount)
            if (discountAmount) {
                const coupon_payload = {coupon: appliedCouponCode, discountAmount}

                if (!_.isEqual(discountAmount, couponDiscountAmount)) setCouponDiscountAmount(discountAmount)
                if (!_.isEqual(couponData, coupon_payload)) setCouponData(coupon_payload)

            }
        }
        console.log('da, dt, cda', discountAmount, discountTotal, couponDiscountAmount)
        if (!_.isEqual(discountAmount, couponDiscountAmount)) setCouponDiscountAmount(discountAmount)
        // console.log(subtractCoupon && discountAmount && discountAmount > 0)
        return (subtractCoupon && discountAmount && discountAmount > 0) ? (total - discountAmount).toFixed(2) : total.toFixed(2)
    }

    const validateVoucherForPopup = (coupon) => {
        let appliesToAny = false
        Object.values(order).forEach(ord => {
            if (coupon.category === ord.category || coupon.category === 'all') {
                appliesToAny = true
            }
        })
        return appliesToAny
    }


    const removeOrder = (index) => {
        const copy = order
        delete copy[index]
        if (Object.keys(order).length < 1) copy[0] = {
            consumer: cObjDefault,
            date: keepDate ? keepDate : new Date().getTime(),
            completion: false,
            location: process.env.REACT_APP_LOCATION,
            booker: localStorage.getItem('__nn_at__') ? 'administrator' : 'online'
        }
        setOrder({...copy})
        // setProgressCounter(1)
        localStorage.setItem('activeOrder', getHighestIndex())
    }

    const getHighestIndex = () => {
        const keyArr = []
        Object.keys(order).forEach(key => keyArr.push(parseInt(key)))
        return Math.max.apply(Math, keyArr)

    }

    return (
        <SiteContext.Provider
            value={{
                calculateTotal,
                couponData,
                validateVoucherForPopup,
                couponDiscountAmount,
                appliedCouponCode,
                setAppliedCouponCode,
                progressCounter,
                setProgressCounter,
                order,
                setOrder,
                popupStatus,
                setPopupStatus,
                category,
                setCategory,
                seatsNeeded,
                setSeatsNeeded,
                seatsAvailable,
                setSeatsAvailable,
                seatsCapRatio,
                setSeatsCapRatio,
                tmpOrder,
                setTmpOrder,
                popupCounter,
                setPopupCounter,
                selectionFinished,
                setSelectionFinished,
                addOrder,
                removeOrder,
                voucher,
                setVoucher,
                errMessage,
                setErrMessage,
                infoMessage,
                setInfoMessage,
                successMessage,
                setSuccessMessage,
                disableVessels,
                setDisableVessels,
                allOptions,
                services,
                settings,
                progressCheck,
                setProgressCheck,
                keepDate,
                setKeepDate
            }}>
            {props.children}
        </SiteContext.Provider>
    )
}

const SiteConsumer = SiteContext.Consumer;

export {SiteConsumer, SiteContext}
export default SiteProvider