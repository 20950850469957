import React, {useContext, useRef, useEffect, useState} from "react";
import { AiFillExclamationCircle } from 'react-icons/ai';
import { SiteContext } from "../../contexts/SiteContext"
import { gsap } from 'gsap'
import axios from "axios";

const PopupVoucher = () => {
    const contextStorage = useContext(SiteContext)
    const tl = gsap.timeline()
    let { fadeIn } = useRef(null)
    const [code, setCode] = useState('')
    const [message, setMessage] = useState(false)

    useEffect(() => {
        tl.to(fadeIn, {
            duration: 0.5, y: 0, ease: "power4.out"
        })
    }, []);

    const addCode = () => {
        if(code !== ''){
            axios.get('https://deinze-kajakt-api.vercel.app/api/coupons/validate/'+code).then(res => {
                console.log(res.data)
                if(res.data === false){
                    setMessage("Deze code is ongeldig. Probeerde je een vouchercode in te voeren? Deze kan je invoeren door 'Ik heb een voucher code' aan te vinken.")
                }else{
                    if(validateCode(res.data)) {
                        contextStorage.setAppliedCouponCode(res.data)
                        // if(contextStorage.couponDiscountAmount) contextStorage.setVoucher(false)
                        if(contextStorage.validateVoucherForPopup(res.data)){
                            contextStorage.setVoucher(false)
                        }else{
                            setMessage(`Kortingscode "${res.data.code}" is niet geldig op geselecteerde activiteit(en).`)
                            contextStorage.setAppliedCouponCode(false)
                        }
                    }else{
                        setMessage("Deze code is verlopen.")
                    }
                }
            }).catch(r => console.log(r))
        }else{
            setMessage("Gelieve een code in te voeren")
        }
    }

    const validateCode = (discount) => {
        if (new Date() >= new Date(discount.validFrom) && new Date() <= new Date(discount.validUntil)) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            {contextStorage.popupStatus && <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-60 w-screen h-screen z-[1000]">
                <div ref={el => fadeIn = el} className="flex flex-col bg-slate-50 p-7 rounded-md transform -translate-y-20">
                    <div className="flex justify-between items-center mb-6">
                        <h2 className=" text-xl font-bold">Kortingscode</h2>
                        <div className="bg-gray-300 rounded-full w-7 h-7 flex justify-center items-center cursor-pointer hover:bg-primaryBlue hover:text-white" onClick={() => contextStorage.setVoucher(false)}>X</div>
                    </div>
                    <div className="flex mb-4">
                        <p>Voer hier je kortingscode in (geen voucher)</p>
                    </div>
                    <div className="flex">
                        {/*<p className="font-lg font-bold mr-6">Code</p>*/}
                        <input type="text" className=" px-2.5 py-1 rounded-md border border-gray-400" placeholder="IB******" value={code} onChange={(e) => setCode(e.target.value)} />
                    </div>
                    {message && <div className="flex items-center my-3">
                        <AiFillExclamationCircle className=" text-xl text-white texaixl border-none mr-4 bg-red-500 rounded-full" />
                        <p className="text-red-500 font-medium max-w-sm">{message}</p>
                    </div>}
                    <button className=" bg-primaryBlue bg w-full py-4 text-center mt-6 text-white rounded-md hover:bg-cyan-800 transition-all font-medium text-xl" onClick={() => addCode()}>Toepassen</button>
                </div>
            </div>
            }
        </>
    )
};
export default PopupVoucher;
