import React, {useEffect, useState, useContext, useRef} from "react";
import {AiOutlineCloudUpload, AiFillCloseCircle, AiFillExclamationCircle} from 'react-icons/ai';
import bcImage from './../assets/images/bc.png'
import {SiteContext} from "../contexts/SiteContext";
import {useForm} from 'react-hook-form'
import OrderRecord from "./OrderRecord";
import PopupVoucher from "./popups/PopupVoucher";
import axios from "axios";
import Loader from "./Loader";

const Payment = () => {
    const objectID = localStorage.getItem('activeOrder') || 0
    var options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
    const contextStorage = useContext(SiteContext)
    const [hide, setHide] = useState(false);
    const {register, handleSubmit} = useForm()
    const [updatedFormData, setUpdatedFormData] = useState({});
    const [check, setCheck] = useState(true);
    const currObj = contextStorage.order[objectID ? objectID : 0]
    const localData = {0: JSON.parse(localStorage.getItem('orders'))}
    const [voucherBtn, setVoucherBtn] = useState(false);
    const [selectedFile, setSelectedFile] = useState(false)
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [total, setTotal] = useState([]);
    const onFileChange = event => {
        setSelectedFile(event.target.files[0])
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (selectedFile) {
            selectedFile?.size > 10 * 1024 * 1024 ? setCheck(false) : setCheck(true)
        }
    }, [selectedFile]);

    const handleRemoveFile = () => {
        setCheck(false)
        setSelectedFile(null)
    }

    const handleVoucher = (e) => {
        console.log(e.target.checked)
        if (e.target.checked) {
            setCheck(false)
        } else {
            setCheck(true)
        }

        const d = updatedFormData
        setVoucherBtn(e.target.checked)
        setUpdatedFormData((prevState) => ({
            ...prevState,
            voucher: e.target.checked
        }))

        const thisOrder = contextStorage.order[objectID ? objectID : 0]
        thisOrder.consumer[e.target.id] = e.target.value
        // contextStorage.setOrientation(curr => ({ ...curr, [objectID ? objectID : 0]: thisOrder }))
    }

    // Calculate total
    useEffect(() => {
        let tmp = []
        Object.values(contextStorage.order).map(val => tmp.push((val.adult * val.priceAdult) + (val.child * val.priceChild) + (val.childY * val.priceChildY)))
        // console.log(tmp)
        const sum = tmp.reduce((accumulator, value) => {
            return accumulator + value
        }, 0)
        setTotal(sum)
    }, []);

    const handleChange = (e) => {
        // if (e.target.value === "") return

        setUpdatedFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))

        const thisOrder = contextStorage.order[objectID ? objectID : 0]
        thisOrder.consumer[e.target.id] = e.target.value

    };

    useEffect(() => {
        console.log(updatedFormData)
        localStorage.setItem('ordered_by', JSON.stringify(updatedFormData));
    }, [updatedFormData]);

    const onSubmit = (data) => { // Actual order placement
        console.log(selectedFile)
        console.log(!!selectedFile)

        setLoaderStatus(true)
        currObj.completion = true

        const fd = new FormData()
        fd.append('orders', JSON.stringify(contextStorage.order))
        fd.append('coupon', JSON.stringify(contextStorage.couponData))
        fd.append('consumer', JSON.stringify(updatedFormData))
        fd.append('location', process.env.REACT_APP_LOCATION)
        fd.append('admin', !!localStorage.getItem('__nn_at__'))
        fd.append('booker', localStorage.getItem('__nn_at__') || 'Online')

        if (selectedFile && check) fd.append('voucher', selectedFile)
        // if(localStorage.getItem('__nn_at__')) fd.append('admin', true)

        axios.post(`${process.env.REACT_APP_BASE_URL}/api/orders/add`, fd, {
            // 'content-type': 'multipart/form-data'
            'content-type': selectedFile.type,
            'content-length': `${selectedFile.size}` || ''
        }).then(r => { // Add order to DB as "unpaid"
            // console.log(r)
            // console.log(r.data)
            // console.log(r.data.ids)
            // console.log("SUCCESS")


            // console.log(voucherBtn)
            // console.log(selectedFile)
            // Mollie
            if (!localStorage.getItem('__nn_at__') && !voucherBtn && !selectedFile) { // Skip Mollie if admin-mode
                console.log('gOI', r.data.general_order_id)
                console.log("addorderres", r.data)
                axios.post(`${process.env.REACT_APP_BASE_URL}/api/payments/create`, { // Create Mollie Payment => sends post request to webhook => changed added orderd to 'Paid' if successful
                    amount: contextStorage.calculateTotal(true),
                    orders: r.data.ids,
                    general_order_id: r.data.general_order_id,
                    location: process.env.REACT_APP_LOCATION,
                    description: contextStorage.order[objectID ? objectID : 0].consumer.firstname + ' ' + contextStorage.order[objectID ? objectID : 0].consumer.lastname + ' - ' + (r.data.ids.length > 1 ? 'Meerdere activiteiten' : contextStorage.order[objectID ? objectID : 0].serviceDetail)
                }).then(payment_url => {
                    contextStorage.setProgressCounter(4)
                    contextStorage.setProgressCheck(4)
                    // console.log(payment_url.data)
                    window.location = payment_url.data
                    // console.log(payment_url)
                }).catch(err => {
                    // console.log(err)
                })
            } else {
                if (voucherBtn && selectedFile) { // Voucher case
                    window.location = '/complete'
                } else { // Admin case
                    window.location = '/complete'
                }
            }

        }).catch(err => {
            // console.log(err)
            // console.log("ERROR")
        })
    }

    return (
        <>
            {contextStorage.voucher && <PopupVoucher/>}
            {loaderStatus && <Loader/>}
            <div
                className={`mx-auto justify-center lg:py-32 pt-10 lg:pt-20 w-full flex-col lg:flex-row ${hide ? 'hidden' : "flex"}`}>
                <div className="bg-slate-100 p-8 rounded-tr-xl rounded-tl-xl lg:rounded-xl flex-col w-full lg:w-3/5">
                    <h2 className=" text-xl font-bold border-b border-gray-400 pb-4 lg:mb-6 my-3 lg:my-0">Uw
                        contactgegevens</h2>
                    <form className="w-full"
                          onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className="flex flex-wrap -mx-3 mb-1">
                            <div className="w-full md:w-1/2 px-3 lg:mb-6 my-3 lg:my-0 md:mb-0">
                                <label className="block tracking-wide text-gray-700 font-medium mb-2">
                                    Voornaam
                                </label>
                                <input required {...register('firstname')}
                                       className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                       id="firstname" type="text" placeholder="Voornaam"
                                       value={contextStorage.order[objectID ? objectID : 0].consumer.firstname || (contextStorage.order[0] && contextStorage.order[0].consumer.firstname) || ''}
                                       onChange={(e) => handleChange(e)} name="firstname"/>
                            </div>
                            <div className="w-full md:w-1/2 px-3">
                                <label className="block tracking-wide text-gray-700 font-medium mb-2">
                                    Achternaam
                                </label>
                                <input required {...register("lastname")}
                                       className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                       id="lastname" type="text" placeholder="Achternaam"
                                       value={contextStorage.order[objectID ? objectID : 0].consumer.lastname || (contextStorage.order[0] && contextStorage.order[0].consumer.lastname) || ''}
                                       onChange={(e) => handleChange(e)} name="lastname"/>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full md:w-1/2 px-3 lg:mb-6 my-3 lg:my-0 md:mb-0">
                                <label className="block tracking-wide text-gray-700 font-medium mb-2">
                                    Email adres
                                </label>
                                <input required {...register('email')}
                                       className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                       id="email" type="email" placeholder="Email"
                                       value={contextStorage.order[objectID ? objectID : 0].consumer.email || (contextStorage.order[0] && contextStorage.order[0].consumer.email) || ''}
                                       onChange={(e) => handleChange(e)} name="email"/>
                            </div>
                            <div className="w-full md:w-1/2 px-3">
                                <label className="block tracking-wide text-gray-700 font-medium mb-2">
                                    GSM
                                </label>
                                <input required {...register('phone')}
                                       className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                       id="phone" type="tel" placeholder="Gsm"
                                       value={contextStorage.order[objectID ? objectID : 0].consumer.phone || (contextStorage.order[0] && contextStorage.order[0].consumer.phone) || ''}
                                       onChange={(e) => handleChange(e)} name="phone"/>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-1">
                            <div className="w-full md:w-4/6 px-3 lg:mb-6 my-3 lg:my-0 md:mb-0">
                                <label className="block tracking-wide text-gray-700 font-medium mb-2">
                                    Straatnaam
                                </label>
                                <input required {...register('street')}
                                       className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                       id="street" type="text" placeholder="Straatnaam"
                                       value={contextStorage.order[objectID ? objectID : 0].consumer.street || (contextStorage.order[0] && contextStorage.order[0].consumer.street) || ''}
                                       onChange={(e) => handleChange(e)} name="street"/>
                            </div>
                            <div className="w-full md:w-2/6 px-3">
                                <label className="block tracking-wide text-gray-700 font-medium mb-2">
                                    Huisnr
                                </label>
                                <input required {...register("number")}
                                       className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                       id="number" type="text" placeholder="Huisnr"
                                       value={contextStorage.order[objectID ? objectID : 0].consumer.number || (contextStorage.order[0] && contextStorage.order[0].consumer.number) || ''}
                                       onChange={(e) => handleChange(e)} name="number"/>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 lg:mb-6 my-3 lg:my-0">
                            <div className="w-full md:w-2/6 px-3">
                                <label className="block tracking-wide text-gray-700 font-medium mb-2">
                                    Postcode
                                </label>
                                <input required {...register("postal")}
                                       className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                       id="postal" type="text" placeholder="Postcode"
                                       value={contextStorage.order[objectID ? objectID : 0].consumer.postal || (contextStorage.order[0] && contextStorage.order[0].consumer.postal) || ''}
                                       onChange={(e) => handleChange(e)} name="postal"/>
                            </div>
                            <div className="w-full md:w-4/6 px-3 my-3 lg:my-0 md:mb-0">
                                <label className="block tracking-wide text-gray-700 font-medium mb-2">
                                    Stad/gemeente
                                </label>
                                <input required {...register('city')}
                                       className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                       id="city" type="text" placeholder="Stad"
                                       value={contextStorage.order[objectID ? objectID : 0].consumer.city || (contextStorage.order[0] && contextStorage.order[0].consumer.city) || ''}
                                       onChange={(e) => handleChange(e)} name="city"/>
                            </div>

                        </div>
                        <div className="flex flex-col lg:flex-row">
                            <div className="w-full md:w-1/2 md:mb-8">
                                <div className="flex items-center mb-4">
                                    <input required id="default-checkbox" type="checkbox"
                                           className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label htmlFor="default-checkbox"
                                           className="ml-2 text-sm font-medium text-gray-590 dark:text-gray-300">Ik heb
                                        de algemene voorwaarden gelezen en ga akkoord.</label>
                                </div>
                                <div className="flex items-center mb-4">
                                    <input id="default-checkbox" type="checkbox"
                                           className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label htmlFor="checked-checkbox"
                                           className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Hou me
                                        op de hoogte van nieuws & aanbiedingen</label>
                                </div>
                                {/*<div className="flex items-center mb-4">*/}
                                {/*    <input id="default-checkbox" value="voucher" onChange={handleVoucher}*/}
                                {/*           type="checkbox"*/}
                                {/*           className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>*/}

                                {/*    <label htmlFor="checked-checkbox"*/}
                                {/*           className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Ik heb*/}
                                {/*        een voucher/bon</label>*/}
                                {/*</div>*/}
                            </div>
                            <div className="flex justify-center items-start w-full md:w-1/2">
                                {/*{voucherBtn && <div className="flex flex-col w-full">*/}
                                {/*    <label*/}
                                {/*        class="custom-file-upload w-full h-16 border border-primaryBlue group hover:bg-primaryBlue hover:text-white rounded-md flex justify-center items-center lg:mb-0">*/}
                                {/*        <ol>*/}
                                {/*            <li>Open de applicatie Social Deal</li>*/}
                                {/*            <li>Selecteer mijn account/menu</li>*/}
                                {/*            <li>Mijn vouchers</li>*/}
                                {/*            <li>Klik op de juiste voucher</li>*/}
                                {/*            <li>Bekijk vouchers</li>*/}
                                {/*            <li>Print icoontje rechtsboven</li>*/}
                                {/*            <li>Download PDF</li>*/}
                                {/*        </ol>*/}
                                {/*        <input type="file" onChange={onFileChange}/>*/}
                                {/*        <AiOutlineCloudUpload className=" inline-block text-3xl mr-2"/> <span*/}
                                {/*        className="font-semibold"> Selecteer uw voucher-bestand. </span>*/}
                                {/*    </label>*/}
                                {/*    /!* <p className=" text-center mt-2 bg-gray-200 rounded-md p-2">Upload hier een foto van uw voucher code. Wij verzilveren uw code om uw boeking te vervolledigen</p> *!/*/}
                                {/*    <div*/}
                                {/*        className=" mt-1.5 lg:mt-2 bg-slate-400 text-white py-1.5 px-3 rounded-md flex justify-between">*/}
                                {/*        <h2 className="">Bestand: <span>{selectedFile?.name ? selectedFile?.name : "Nog geen bestand gekozen"}</span>*/}
                                {/*        </h2>*/}
                                {/*        {selectedFile?.name &&*/}
                                {/*        <button type="button" onClick={handleRemoveFile}><AiFillCloseCircle*/}
                                {/*            className=" text-2xl hover:text-primaryBlue text-white"/></button>}*/}
                                {/*    </div>*/}
                                {/*    {selectedFile && selectedFile.size > 10 * 1024 * 1024 &&*/}
                                {/*    <p className=" text-center mt-2 bg-red-200 rounded-md p-2 mb-5">Bestand is te*/}
                                {/*        groot</p>}*/}
                                {/*</div>}*/}
                                {voucherBtn && <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-60 w-screen h-screen z-[1000]">
                                    <div className="flex bg-slate-50 p-7 rounded-md transform md:-translate-y-20">
                                        <div className="flex flex-col">
                                            <div className="flex justify-between items-center mb-6">
                                                <h2 className=" text-xl font-bold">Voucher uploaden</h2>
                                                <div className="bg-gray-300 rounded-full w-7 h-7 flex md:hidden justify-center items-center cursor-pointer hover:bg-primaryBlue hover:text-white" onClick={() => setVoucherBtn(false)}>X</div>
                                            </div>
                                            <div className="flex md:hidden flex-col pl-3 mb-8">
                                                <p>Je voucher-PDF uploaden:</p>
                                                <ol className="list-decimal ml-2">
                                                    <li>Open de voucher applicatie</li>
                                                    <li>Selecteer "mijn-account/menu"</li>
                                                    <li>Klik op "Mijn vouchers"</li>
                                                    <li>Selecteer de juiste voucher</li>
                                                    <li>Ga naar "Bekijk vouchers"</li>
                                                    <li>Klik op het print-icoontje rechtsboven</li>
                                                    <li>Download PDF</li>
                                                </ol>
                                                <p>Dit PDF-bestand is geldig om hier te uploaden.</p>
                                            </div>
                                            <label
                                                className="custom-file-upload w-full h-16 border border-primaryBlue group hover:bg-primaryBlue hover:text-white rounded-md flex justify-center items-center lg:mb-0">
                                                <input type="file" onChange={onFileChange}/>
                                                <AiOutlineCloudUpload className=" inline-block text-3xl mr-2"/> <span
                                                className="font-semibold"> Selecteer uw voucher-bestand. </span>
                                            </label>
                                            {/* <p className=" text-center mt-2 bg-gray-200 rounded-md p-2">Upload hier een foto van uw voucher code. Wij verzilveren uw code om uw boeking te vervolledigen</p> */}
                                            <div
                                                className=" mt-1.5 lg:mt-2 bg-slate-400 text-white py-1.5 px-3 rounded-md flex justify-between">
                                                <h2 className="">Bestand: <span>{selectedFile?.name ? selectedFile?.name : "Nog geen bestand gekozen"}</span>
                                                </h2>
                                                {selectedFile?.name &&
                                                <button type="button" onClick={handleRemoveFile}><AiFillCloseCircle
                                                    className=" text-2xl hover:text-primaryBlue text-white"/></button>}
                                            </div>
                                            {selectedFile && selectedFile.size > 10 * 1024 * 1024 &&
                                            <p className=" text-center mt-2 bg-red-200 rounded-md p-2 mb-5">Bestand is te
                                                groot</p>}
                                            <div className=" bg-primaryBlue bg w-full py-4 text-center mt-6 text-white rounded-md hover:bg-cyan-800 transition-all font-medium text-xl cursor-pointer" onClick={() => setVoucherBtn(false)}>Uploaden</div>
                                        </div>
                                        <div className="flex ml-8">
                                            <div className="flex">
                                                <div className="hidden md:flex flex-col pl-3 mb-8">
                                                    <strong>Je voucher-PDF uploaden:</strong>
                                                    <ol className="list-decimal ml-4 my-2">
                                                        <li>Open de voucher applicatie</li>
                                                        <li>Selecteer "mijn-account/menu"</li>
                                                        <li>Klik op "Mijn vouchers"</li>
                                                        <li>Selecteer de juiste voucher</li>
                                                        <li>Ga naar "Bekijk vouchers"</li>
                                                        <li>Klik op het print-icoontje rechtsboven</li>
                                                        <li>Download PDF</li>
                                                    </ol>
                                                    <p>Dit PDF-bestand is geldig om hier te uploaden.</p>
                                                </div>
                                            </div>
                                            <div className="bg-gray-300 rounded-full w-7 h-7 hidden md:flex justify-center items-center cursor-pointer hover:bg-primaryBlue hover:text-white" onClick={() => setVoucherBtn(false)}>X</div>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row md:justify-between mt-3 md:mt-0">
                            <button type="button"
                                    className="w-full text-center text-white rounded-md transition-all text-md mb-2 bg-primaryBlue hover:bg-cyan-600 cursor-pointer py-4 md:py-3 md:mr-1"
                                    onClick={() => setVoucherBtn(true)}>Ik heb een voucher/bon
                            </button>
                            <button type="button"
                                    className="bg-transparent hover:bg-primaryBlue border-primaryBlue border-2 text-primaryBlue mb-2 bg w-full text-center hover:text-white rounded-md transition-all text-md px-4 py-4 md:py-3 md:ml-1"
                                    onClick={() => contextStorage.setVoucher(true)}>Ik heb een kortingscode (IB*****)
                            </button>
                        </div>
                        <input type="submit"
                               className={`w-full py-4 text-center text-white rounded-md transition-all text-md px-4 ${selectedFile ? check ? "bg-primaryBlue hover:bg-cyan-600 cursor-pointer" : " bg-gray-300" : "bg-primaryBlue hover:bg-cyan-600 cursor-pointer"} `}
                               disabled={selectedFile ? check ? false : true : false}
                               value={check && selectedFile && selectedFile.size <= 10 * 1024 * 1024 ? 'Bestellen met voucher' : `Betaal nu € ${contextStorage.calculateTotal(true)}`}/>
                        {voucherBtn && !check ?
                            <p className=" text-center mt-2 bg-red-200 rounded-md p-2"><strong className=" mr-1">Opgelet
                                verplicht!</strong>Bij keuze voucher verplicht voucher up te loaden
                            </p> : ''}
                    </form>
                    <div className="flex items-center mb-5 mt-5">
                        <p className=" mr-2">Betaal met</p>
                        <img src={bcImage} alt="" className=" w-10"/>
                    </div>
                </div>
                <div className="w-6"/>
                <div className="bg-slate-100 p-8 lg:rounded-xl rounded-br-xl rounded-bl-xl flex-col w-full lg:w-2/5">
                    <h2 className=" text-xl font-bold my-3 lg:my-0">Uw reservatie</h2>
                    {/* <div className="flex text-lg">
                        <h3 className=" mr-12 whitespace-nowrap">Geselecteerde datum <br /> <strong>{new Date(currObj.date).toLocaleDateString("nl-BE", options)}2</strong></h3>
                    </div> */}
                    <div className="w-full border-b border-gray-400 pt-4"></div>
                    {contextStorage.order ? Object.entries(contextStorage.order).map(([key, object]) =>
                        <OrderRecord index={key} data={object} completion={object.completion}/>) : ''}
                    <p className="font-bold text-lg ml-auto mt-4 text-right">Totaal
                        € {contextStorage.calculateTotal()} </p>
                    {contextStorage.couponDiscountAmount &&
                    <p className="font-bold text-lg ml-auto mt-4 text-right">Kortingscode
                        ({contextStorage.appliedCouponCode.code})&nbsp;&nbsp; -
                        € {contextStorage.couponDiscountAmount} </p>}
                    {contextStorage.couponDiscountAmount &&
                    <p className="font-bold text-lg ml-auto mt-4 text-right">Te betalen
                        € {contextStorage.calculateTotal(true)} </p>}
                    {selectedFile && check &&
                    <p className="font-bold text-md ml-auto mt-4 text-right">Te betalen €0 <span className="font-normal">(na bevestiging voucher)</span></p>}
                </div>
            </div>
        </>
    )


}

export default Payment