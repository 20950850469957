import React, {useState, useContext, useEffect, useRef} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {SiteContext} from "../contexts/SiteContext";
import OrderRecord from "./OrderRecord";
import Locale from "./Locale";
import axios from "axios"
import format from "date-fns/format";
import {addDays} from "date-fns/esm";
import {BsFillInfoCircleFill} from 'react-icons/bs'
import gsap from 'gsap'

const SelectDate = () => {

    let {gLoader} = useRef(null)
    const objectID = localStorage.getItem('activeOrder') || 0
    var options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
    const [selectedDate, setSelectedDate] = useState(undefined);
    const [hide, setHide] = useState(false);
    const contextStorage = useContext(SiteContext)
    const [excludedDates, setExcludedDates] = useState([])
    const [extra, setExtra] = useState(false);

    useEffect(() => {
        if (!excludedDates || !excludedDates.length) return
        let dateCheck = format(new Date(), 'yyyy-MM-dd')
        let tmpArr = []
        excludedDates?.map(el => {
            tmpArr.push(format(el, 'yyyy-MM-dd'))
        })

        console.log('prewhile dt', dateCheck)
        console.log('tmpArr', tmpArr)
        console.log('excludedDates', excludedDates)
        while (tmpArr.includes(dateCheck)) {
            console.log('MATCH @', dateCheck)
            let nDate = addDays(new Date(dateCheck), 1)
            console.log('nd', nDate)
            dateCheck = format(nDate, 'yyyy-MM-dd')
            console.log('added dt', dateCheck)
        }

        console.log('KD HERE', contextStorage.keepDate)
        if (contextStorage.keepDate !== undefined) {
            console.log('KEEPDATE set')
            setSelectedDate(new Date(contextStorage.keepDate))
            // localStorage.setItem('__nn_kd__', contextStorage.keepDate)
            // console.log('contextStorage.keepDate', contextStorage.keepDate)
        }else{
            console.log('NEWDATE set', dateCheck)
            setSelectedDate(new Date(dateCheck))
            // localStorage.setItem('__nn_kd__', dateCheck)
            // console.log('dateCheck', dateCheck)
        }
        // console.log('HERE', new Date(parseInt(localStorage.getItem('__nn_kd__'))))
    }, [excludedDates])

    useEffect(() => {


        const fd = new FormData()
        fd.append('admin', !!localStorage.getItem('__nn_at__'))

        // Display the key/value pairs
        for (var pair of fd.entries()) {
            console.log(pair[0]+ ' => ' + pair[1]);
        }

        gsap.to(gLoader, {
            opacity: 0, duration: .8, repeat: -2
        })
        axios.get('https://deinze-kajakt-api.vercel.app/api/calendar/deinze').then(r => {
            const formattedDates = []
            r.data.forEach(entry => formattedDates.push(new Date(entry.data.time_string)))
            setExcludedDates(formattedDates)
            console.log(formattedDates)
        })
    }, [])

    useEffect(() => {
        console.log('setter', selectedDate)
        if (!selectedDate) return
        const copy = contextStorage.order
        if (!copy[objectID].completion) copy[objectID].date = selectedDate.getTime()
        if(selectedDate !== undefined) contextStorage.setKeepDate(selectedDate.getTime())
        if (!copy[objectID].completion) contextStorage.setOrder({...copy})
    }, [selectedDate]);



    const nextStep = () => {
        setHide(!hide)
        contextStorage.setProgressCounter(contextStorage.progressCounter + 1)
        contextStorage.setProgressCheck(contextStorage.progressCheck + 1)
        localStorage.setItem('progressCounter', '2');
    }

    return (
        <div
            className={`${hide ? "hidden" : "flex flex-col lg:flex-row max-w-screen-2xl mx-auto justify-center pt-10 lg:pt-20 lg:pb-32 w-full"}`}>
            <div
                className="bg-slate-100 p-8 rounded-tr-xl rounded-tl-xl lg:rounded-xl flex-col custom-datepicker relative lg:w-1/2">
                <h2 className=" text-xl font-bold border-b border-gray-400 pb-4 lg:mb-6 text-center lg:text-left">Selecteer
                    uw datum</h2>
                <div className=" relative flex flex-col justify-center items-center py-10 pb-0 lg:pb-10">
                    <div
                        className={`bg-gray-200 bg-opacity-0 absolute inset-0 z-[1] w-full h-full pointer-events-none ${contextStorage.order[objectID ? objectID : 0] && contextStorage.order[objectID ? objectID : 0].completion && contextStorage.order[objectID ? objectID : 0].completion == true ? 'block' : "hidden"}`}/>
                    {selectedDate != undefined && excludedDates.length > 0 &&
                    <DatePicker
                        selected={selectedDate}
                        calendarStartDay={1}
                        onChange={(date) => setSelectedDate(date)}
                        excludeDates={excludedDates?.length && excludedDates}
                        minDate={new Date()}
                        locale={Locale}
                        inline
                    />}
                    {excludedDates.length == 0 &&
                    <p ref={el => gLoader = el} className=" text-2xl font-medium text-center">Een ogenblik geduld,
                        beschikbare data worden opgehaald</p>}
                    <h2 className=" block lg:hidden text-xl text-center font-bold mt-6"><span
                        className="font-light"> Geselecteerde datum <br/></span>{new Date(selectedDate).toLocaleDateString("nl-BE", options)}
                    </h2>
                </div>
            </div>
            <div className="w-6"/>
            <div className="bg-slate-100 px-4 xl:px-8 lg:rounded-xl rounded-br-xl rounded-bl-xl flex-col lg:w-1/2 pb-6">
                <h2 className=" text-xl font-bold border-b border-gray-400 pb-4 mb-6 mt-8">Uw reservatie</h2>
                <h3 className=" mb-6">Selecteer uw datum om te reserveren bij <strong>Deinze Kajakt</strong></h3>
                <BsFillInfoCircleFill className="block lg:hidden text-xl mr-4 text-sky-500 mb-5"
                                      onClick={() => setExtra(!extra)}/>

                <div className="hidden lg:flex flex-col">
                    <div className="flex mb-4 items-center">
                        <div className="dk-orange w-10 h-10 mr-6 rounded-md"></div>
                        <p>Geselecteerde datum</p>
                    </div>
                    <div className="flex mb-4 items-center">
                        <div className="dk-green w-10 h-10 mr-6 rounded-md"></div>
                        <p>Beschikbaar </p>
                    </div>
                    {/*<div className="flex mb-4 items-center">*/}
                    {/*    <div className="dk-red w-10 h-10 mr-6 rounded-md"></div>*/}
                    {/*    <p>Volboekt</p>*/}
                    {/*</div>*/}
                    <div className="flex mb-4 items-center">
                        <div className="bg-slate-200 w-10 h-10 mr-6 rounded-md"></div>
                        <p>Gesloten</p>
                    </div>
                </div>
                <div className={`${extra ? "flex" : "hidden"} lg:hidden flex-col`}>
                    <div className="flex mb-4 items-center">
                        <div className="dk-orange w-10 h-10 mr-6 rounded-md"></div>
                        <p>Vandaag</p>
                    </div>
                    <div className="flex mb-4 items-center">
                        <div className="dk-green w-10 h-10 mr-6 rounded-md"></div>
                        <p>Beschikbaar </p>
                    </div>
                    <div className="flex mb-4 items-center">
                        <div className="dk-red w-10 h-10 mr-6 rounded-md"></div>
                        <p>Volboekt</p>
                    </div>
                    <div className="flex mb-4 items-center">
                        <div className="bg-slate-200 w-10 h-10 mr-6 rounded-md"></div>
                        <p>Gesloten</p>
                    </div>
                </div>

                <h2 className="hidden lg:block text-xl font-bold mt-6"><span
                    className="font-light"> Geselecteerde datum <br/></span> {new Date(selectedDate).toLocaleDateString("nl-BE", options)}
                </h2>
                <div className="w-full border-b border-gray-400 lg:pt-4 lg:py-6"></div>
                {contextStorage.order ? Object.entries(contextStorage.order).map(([key, object]) =>
                        <OrderRecord index={key} data={object} completion={object.completion}/>)
                    : ''}
                <p className="font-bold text-lg ml-auto mt-4 text-right">Totaal € {contextStorage.calculateTotal()} </p>

                <button
                    className={`bg-primaryBlue bg w-full py-4 text-center mt-6 text-white rounded-md hover:bg-cyan-800 transition-all font-medium text-xl`}
                    onClick={() => nextStep()}>Volgende
                </button>
            </div>
        </div>
    )


}

export default SelectDate