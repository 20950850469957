import Logo from './../assets/images/logo/logo.svg'
import { AiOutlinePhone } from 'react-icons/ai';

const Header = () => {

    return (
        <div className=" w-full bg-primaryBlue border-r border-slate-100 px-2 pr-4 text-white">
            <div className=" py-7 flex justify-between items-center max-w-screen-2xl mx-auto">
                <a href="https://deinzekajakt.be"><img src={Logo} alt="logo" className=' mx-4 w-24 lg:w-auto' /></a>
                <div className="flex">
                    <AiOutlinePhone className=' text-xl lg:text-3xl mr-2 font-light'/>
                    <a href="tel:094968966" className=' block lg:text-2xl font-light hover:underline transition-all'>09/496.89.66</a>
                </div>
            </div>

        </div >
    )
};
export default Header;
