import {
    createBrowserRouter, RouterProvider,
} from "react-router-dom";
import SiteProvider, {SiteContext} from "./contexts/SiteContext";
import Home from './pages/Home';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "/:objectID",
        element: <Home/>,
    },
    {
        path: "/complete",
        element: <Home complete={true}/>
    },
    {
        path: "/admin/:at",
        element: <Home at={true}/>
    }
]);

function App() {
    return (
        <SiteProvider>
            <RouterProvider router={router}/>
        </SiteProvider>
    );
}

export default App;