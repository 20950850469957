import React, { useState, useEffect, useContext } from "react";
import { SiteContext } from "../../contexts/SiteContext"
import { useParams } from "react-router";
import _ from 'lodash';
import axios from "axios";

const Counter = ({ label, price, type, capacity, disabled, isOption, timeslot, isVessel }) => {
    const objectID = localStorage.getItem('activeOrder') || 0
    const contextStorage = useContext(SiteContext)
    const currObj = contextStorage.order[objectID ? objectID : 0]

    // useEffect(() => {
    //     console.log(capacity)
    // }, []);

    const [services, setServices] = useState(false)

    useEffect(() => {
        axios.get("https://deinze-kajakt-api.vercel.app/api/services").then(res => {
            // console.log(res.data)
            setServices(res.data)
        }).catch(err => console.log(err))
        console.log(type)
    }, []);

    // console.log(`<Counter type={${type}} />`)

    useEffect(() => {
        const tmp = currObj
        tmp.child = currObj.child || 0
        tmp.vessel_amount = currObj.vessel_amount || 0
        tmp.adult = currObj.adult || 0
        tmp.kayak_1p = currObj.kayak_1p || 0
        tmp.kayak_2p = currObj.kayak_2p || 0
        tmp.kayak_3p = currObj.kayak_3p || 0
        tmp.sup_1p = currObj.sup_1p || 0
        tmp.sup_2p = currObj.sup_2p || 0
        tmp.capacity = currObj.capacity || 0
        tmp.helmet = currObj.helmet || 0
        tmp.jacket = currObj.jacket || 0
        tmp.capacity = currObj.capacity || 0

        contextStorage.setOrder((prevState) => ({
            ...prevState,
            [objectID ? objectID : 0]: tmp
        }))
        // console.log('!isOption', !isOption)
    }, []);


    const handleIncrease = (type) => {
        const amount = 1;

        console.log('')
        console.log('')
        console.log('handleIncrease ---')

        console.log('amount', amount)

        if (contextStorage.order[objectID][type] !== undefined) {
            console.log('contextStorage.order[objectID][type] !== undefined', contextStorage.order[objectID][type] !== undefined)
            const clone = _.cloneDeep(contextStorage.order[objectID])
            console.log(`type '${type}' pre`, clone[type])
            clone[type] += 1
            console.log(`type '${type}' post`, clone[type])
            console.log('cap pre', clone['capacity'])
            clone['capacity'] += parseInt(amount)
            console.log('cap post', clone['capacity'])
            contextStorage.setOrder(prevState => ({
                ...prevState,
                [objectID]: clone
            }))

        } else {
            contextStorage.setOrder({ ...contextStorage.order, [objectID]: { ...contextStorage.order[objectID], [type]: 1 } })
            contextStorage.setOrder({ ...contextStorage.order, [objectID]: { ...contextStorage.order[objectID], capacity: contextStorage.order[objectID].capacity + parseInt(amount[0]) } })
        }
        console.log('--- handleIncrease')
        console.log('')
        console.log('')
    }

    const handleDecrease = (type, capacity, disabled) => {
        const tmp = currObj
        if (currObj[type] > 0) {
            tmp[type] = currObj[type] - 1
            if (capacity) tmp.capacity = capacity != undefined || capacity != 0 ? currObj.capacity - capacity : 0
            contextStorage.setOrder((prevState) => ({
                ...prevState,
                [objectID ? objectID : 0]: tmp,
            }))

            if ((currObj.capacity - capacity) < currObj.seatsNeeded || (currObj.capacity - capacity) == NaN || (currObj.capacity - capacity) == undefined) {
                // console.log(`maak voertuig terug beschikbaar`)
                let tmp = contextStorage.disableVessels
                // console.log(tmp)
                let filteredArray = tmp.filter((e) => { return e !== type })
                // console.log(filteredArray)
                tmp.includes(type) && contextStorage.setDisableVessels(filteredArray)
            }
        }
    }

    const handleVesselIncrease = () => {
        // console.log(contextStorage.order[objectID][type])
        if (contextStorage.order[objectID ? objectID : 0].vessels !== undefined) {
            // console.log('exist')
            const clone = _.cloneDeep(contextStorage.order[objectID])
            const vessels = clone.vessels
            vessels[type] = (vessels[type] || 0) + 1
            clone.vessels = vessels
            clone['capacity'] += parseInt(capacity)
            contextStorage.setOrder(prevState => ({
                ...prevState,
                [objectID]: clone
            }))

        } else {

            const clone = _.cloneDeep(contextStorage.order[objectID])
            const vessels = {}
            vessels[type] = (vessels[type] || 0) + 1
            clone.vessels = vessels
            clone['capacity'] += parseInt(capacity)
            contextStorage.setOrder(prevState => ({
                ...prevState,
                [objectID]: clone
            }))
        }
    }

    // useEffect(() => {
    //     console.log(contextStorage)
    // }, [contextStorage]);

    useEffect(() => {
        console.log('TRIGGER CAPACITY')
        const tmp = currObj
        if(currObj.vessel_amount){
            console.log('currObj.vessel_amount', !!currObj.vessel_amount)
            tmp.seatsNeeded = currObj.vessel_amount
        }else{
            tmp.seatsNeeded = currObj.child + currObj.adult
        }
        contextStorage.setOrder((prevState) => ({
            ...prevState,
            [objectID ? objectID : 0]: tmp
        }))
    }, [currObj.adult, currObj.child, currObj.vessel_amount]);

    useEffect(() => {
        contextStorage.setSeatsCapRatio(currObj.capacity - currObj.seatsNeeded)
    }, [currObj.capacity]);


    // useEffect(() => {
    //     contextStorage.setOrder((prevState) => ({
    //         ...prevState,
    //         seatsNeeded: currObj.child + currObj.adult
    //     }))
    // }, [currObj.adult, currObj.child]);

    // useEffect(() => {
    //     contextStorage.setSeatsCapRatio(currObj.capacity - currObj.seatsNeeded)
    // }, [currObj.capacity]);

    const validateMaxPeople = () => {
        if (!services) return
        // console.log("${type != 'adult' && type != 'child' && type != 'childY'")

        if (type === 'adult' || type === 'child') {
            // console.log(timeslot)
            // console.log(services)
            // console.log(contextStorage.order[objectID ? objectID : 0])
            // console.log(services.find(service => service.id === contextStorage.order[objectID || 0].serviceID)) // TODO LEFTOFF NOW WE HAVE THE SERVICE, GET THE VESSEL TYPE FROM IT
            return false
        } else {
            return true
        }
    }

    const getValue = () => {
        if (isVessel) {
            if (contextStorage.order[objectID].vessels && contextStorage.order[objectID].vessels[type]) {
                return contextStorage.order[objectID].vessels[type]
            } else {
                return 0
            }
        } else {
            return contextStorage.order[objectID][type]
        }
    }

    return (
        <div className="flex justify-between mb-5">
            <p className=" mr-5 lg:mr-14 font-bold text-lg">{label}</p>
            <div className={`flex justify-between items-center ${price ? ' w-[11rem] lg:w-[13rem]' : ' w-auto'}`}>
                <div className={`flex h-10 bg-white rounded-md overflow-hidden border border-gray-300`}>
                    <div className={`h-full w-8 flex justify-center items-center px-3 text-white text-xl bg-primaryBlue hover:bg-cyan-800 cursor-pointer`}
                        onClick={() => handleDecrease(type, capacity, disabled)}>-</div>
                    <div className="w-16 h-full flex justify-center items-center">{getValue()}</div>

                    <div className={`h-full w-8 flex justify-center items-center px-3 text-white text-xl bg-primaryBlue hover:bg-cyan-800 cursor-pointer 
                    ${isVessel && (contextStorage.order[objectID].capacity == contextStorage.order[objectID].seatsNeeded || contextStorage.order[objectID].capacity + capacity > contextStorage.order[objectID].seatsNeeded) ? " bg-gray-300 pointer-events-none" : " bg-primaryBlue"}`}
                        onClick={() => isVessel ? handleVesselIncrease() : handleIncrease(type)}>+</div>

                    {/*{type != 'adult' && type != 'child' && type != 'childY' && contextStorage.order[objectID].seatsNeeded + ' : '}*/}
                    {/*{type != 'adult' && type != 'child' && type != 'childY' && contextStorage.order[objectID].capacity}*/}
                </div>
                {price && <p className=" ml-2 lg:ml-0 font-bold">€ {(currObj[type] * price).toFixed(2)}</p>}
            </div>
        </div>
    )
};

export default Counter;
