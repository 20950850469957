import React, { useState, useContext, useEffect } from "react";
const days = ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za']
const months = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December']

const Locale = {
    localize: {
        day: n => days[n],
        month: n => months[n]
    },
    formatLong: {
        date: () => 'mm/dd/yyyy'
    }
}

export default Locale