import React, { useState, useContext, useEffect } from "react";
import Confirmation from "../components/Confirmation";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Payment from "../components/Payment";
import ProgressBar from "../components/progressBar";
import SelectDate from "../components/SelectDate";
import SelectService from "../components/SelectService";
import { SiteContext } from "../contexts/SiteContext"
import { useSearchParams } from "react-router-dom";
import { useParams } from "react-router";

const Home = ({ complete, at }) => {

    const generalContext = useContext(SiteContext)
    const progress = useContext(SiteContext).progressCounter

    useEffect(() => {

        if (at) {
            const accessToken = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]

            if (accessToken) {
                console.log(accessToken)
                localStorage.setItem('__nn_at__', accessToken)
                window.location = '/'
            } else {
                console.log('NO AT')
            }
        }
    }, []);

    return (
        <>
            <Header />

            <div className=" mx-2 lg:mx-5">
                <div className="flex flex-col rounded-lg max-w-screen-2xl mx-auto my-10">
                    <ProgressBar />
                    {/* <SelectDate /> */}
                    {complete ? <Confirmation />
                        : progress == 1 ? <SelectDate />
                            : progress == 2 ? <SelectService />
                                : progress == 3 ? <Payment />
                                    : <Confirmation />}
                    {/* <Confirmation />
                    <SelectDate />
                    <SelectService />
                    <Payment /> */}
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Home
