const Loader = () => {

    return (
        <div className=" fixed inset-0 w-full h-full flex flex-col justify-center items-center bg-black bg-opacity-80">
            <div class="lds-ripple"><div></div><div></div></div>
            <p className="font-light text-xl mt-2 text-stone-200">Even geduld aub</p>
        </div>
    )
};
export default Loader;
