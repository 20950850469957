import React, { useState, useContext, useEffect } from "react";
import { SiteContext } from "../contexts/SiteContext";
import { useLocation } from "react-router-dom";

const ProgressBar = () => {

    const progress = useContext(SiteContext).progressCounter
    const contextStorage = useContext(SiteContext)
    const cObjDefault = {
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        street: "",
        number: "",
        city: "",
        postal: ""
    }

    const handleClick = (number) => {
        console.log(contextStorage.progressCheck)
        if (number > contextStorage.progressCheck) return
        contextStorage.setProgressCounter(number)
        if (window.location.pathname == '/complete') {
            localStorage.removeItem('activeOrder')
            contextStorage.setOrder({
                0: {
                    consumer: cObjDefault,
                    date: new Date().getTime(),
                    completion: false,
                    location: process.env.REACT_APP_LOCATION,
                    booker: localStorage.getItem('__nn_at__') ? 'administrator' : 'online'
                }
            })
            contextStorage.setProgressCounter(4)
            window.location = '/'
        }
    }

    useEffect(() => {
        // console.log(progress)
    }, [progress]);

    useEffect(() => {
        // console.log(contextStorage.progressCounter)
    }, [contextStorage.setProgressCounter]);


    const data = [{ index: 1, text: "Datum & uur", left: '-left-5' },
    { index: 2, text: "Activiteit naar keuze", left: '-left-12' },
    { index: 3, text: "Contactgegevens", left: '-left-9' },
    { index: 4, text: "Bestelling & betaling", left: '-left-14' }]

    return (
        <div className="flex flex-col w-full max-w-screen-2xl items-center mt-4 lg:mt-20 mx-auto left lg:px-12">
            <p className=" block lg:hidden mb-7 text-2xl font-bold"> {data[contextStorage.progressCounter - 1].text} </p>
            <div className="flex w-full items-center">
                {data.map((item, index) =>
                    <>
                        <div className="relative">
                            <div className={`h-10 w-10 rounded-full px-5 text-center text-white items-center justify-center flex mx-2 ${progress == index + 1 || progress > index + 1 ? 'bg-primaryBlue' : 'bg-gray-300'} cursor-pointer hover:scale-105 transition-all`} onClick={() => handleClick(item.index)}>{item.index} </div>
                            <p className={` hidden md:block absolute top-12 ${item.left} font-bold text-cyan-900 whitespace-nowrap`}>{item.text} </p>
                        </div>
                        {index > 2 ? '' : <div className={`h-px w-full bg-gray-300 ${progress == index + 1 || progress > index + 1 ? 'bg-primaryBlue rounded-full' : 'bg-gray-300'}`} />}
                    </>
                )}
            </div>
        </div>
    )
}

export default ProgressBar